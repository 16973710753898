import React, { useEffect } from 'react';
import { useAuthProvider } from 'react-admin';
import { connect } from 'react-redux';
import { featureSwitch } from '../../utils';
import { useHistory } from 'react-router-dom';
import tokenManager from '../../tokenManager';

export const withFeatureToggleCheck = (WrappedComponent, featureProps) => {
    return (props) => {
        if (featureSwitch(...featureProps)) {
            return (
                <WrappedComponent {...props} />
            );
        }
        return null
    }
};

const withAuthCheck = (CustomComponent, permissionToCheck) => {
    return (props) => {
        const authProvider = useAuthProvider();
        const history = useHistory();

        if (tokenManager.isTwoFactor()) {
            history.push('/2fa');

            return null;
        }

        const handleAuthCheckFail = () => {
            authProvider.logout({}).then(() => history.push('/login'));
        };

        useEffect(() => {
            authProvider.checkAuth({}).catch(handleAuthCheckFail);
        }, []);

        if (!permissionToCheck || (props.permissions && props.permissions[permissionToCheck])) {
            return <CustomComponent {...props} />
        }

        return null;
    }
};

export const with2faCheck = (Component) => {
    return (props) => {
        const history = useHistory()

        if (tokenManager.isTwoFactor()) {
            history.push('/2fa');

            return null;
        }

        return <Component {...props} />
    }
}

export const withPermissionsAndAuthCheck = (Component, permissionToCheck = '') => {
    const mapStateToProps = state => ({
        permissions: state.basicInfo.permissions,
    });

    return connect(mapStateToProps)(withAuthCheck(Component, permissionToCheck));
};
