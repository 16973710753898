import React, { useState, useEffect } from 'react';
import {
    Typography,
    makeStyles,
    Card,
    CardContent,
} from '@material-ui/core';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import ErrorIcon from '@material-ui/icons/Error';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useDataProvider } from 'react-admin';
import { ExternalLink } from '../../Components/Links';
import Button from '../../Components/Buttons/CustomButton'
import { formatSimpleListDate } from '../../utils';
import { VfTick } from "../../icons/icons";
import * as analytics from '../../analytics';

const useStyles = makeStyles(theme => ({
    risk: {
        border: `1px solid ${theme.palette.error.main}`,
        backgroundColor: 'rgba(228, 59, 88, 0.02)',
    },
    opportunity: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        backgroundColor: 'rgba(31, 175, 131, 0.02)',
    },
    riskText: {
        color: `${theme.palette.error.main} !important`,
    },
    opportunityText: {
        color: `${theme.palette.secondary.dark} !important`,
    },
    riskBg: {
        backgroundColor: `${theme.palette.error.main} !important`,
    },
    opportunityBg: {
        backgroundColor: `${theme.palette.secondary.dark} !important`,
    },
    icon: {
        marginRight: '15px',
        verticalAlign: 'top',
        fontSize: '28px',
    },
    header: {
        marginTop: '1.3rem !important',
        marginBottom: '1.3rem !important',
    },
    cardRoot: {
        position: 'relative',
        width: '100%',
        maxWidth: 900,
        padding: '16px 32px',
        borderRadius: '12px',
        '& .MuiTypography-root': {
            lineHeight: 1.3,
            fontSize: '0.875rem',
            marginBottom: 0,
            marginTop: 0,
        },
        '& .MuiTypography-body2': {
            fontSize: '0.7rem',
            fontStyle: 'italic',
        },
        margin: '1.3rem 0',
        '& .MuiCardContent-root': {
            padding: '0 !important',
        },
        '& .MuiCardActions-root': {
            padding: '8px 0 !important',
        },
        '& .MuiTypography-h6': {
            padding: '0 !important',
            fontSize: '1rem',
            fontWeight: 500,
        },
        '& .MuiFormControlLabel-labelPlacementStart': {
            marginLeft: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
        },
        '& .MuiIconButton-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '24px',
            maxWidth: '300px',
        },
    },
    wrapper: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem !important',
        flexBasis: 0,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        },
    },
    wrapperTextMain: {
        flexGrow: 4,
        [theme.breakpoints.down('md')]: {
            flexGrow: 3,
        },
    },
    wrapperTextSecondary: {
        flexGrow: 2,
        marginBottom: 0,
        '& .MuiButton-text': {
            padding: 0,
            '& .MuiButton-label': {
                justifyContent: 'flex-end',
            }
        },
        display: 'flex',
        justifyContent: 'flex-end !important',
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    cardWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        flexDirection: 'column',
        flexWrap: 'wrap',
        flexGrow: 0,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    predictedValue: {
        fontSize: '0.7rem',
    },
    title: {
        color: `${theme.palette.primary.main} !important`,
    },
    externalLink: {
        paddingTop: '10px',
    },
    actionedRibbon: {
        position: 'absolute',
        left: -30,
        top: -6,
        width: '90px !important',
        transform: 'rotate(-45deg)',
        padding: 16,
        paddingBottom: 4,
        textAlign: 'center',
        color: '#fff',
        '& .vfTick': {
            fontSize: 18,
            transform: 'rotate(45deg)',
        }
    },
}));

const RisksAndOpportunities = props => {
    const [data, setData] = useState([]);
    const { isOpportunity, visible } = props;
    const classes = useStyles();
    const dataProvider = useDataProvider();

    useEffect(() => {
        setData(props.data);
    }, []);

    if (!data || data.length === 0) return null;

    if (!visible) return null;

    const handleActionChange = async (actioned, item, key) => {
        if (actioned) {
            const {id} = item.action
            data[key].action.completed_at = new Date();
            analytics.track('Mark as actioned', {actionId: id})
        } else {
            data[key].action.completed_at = null;
        }
        setData([...data]);
        const payload = {
            id: item.action.id,
            data: {
                value: actioned,
            },
        };
        await dataProvider.update('action', payload);
    };

    const getHelperText = () => {
        if (data.length === 0) return '';

        if (isOpportunity) {
            return `${data.length} ${data.length === 1 ? 'Opportunity' : 'Opportunities'}`;
        }

        return `${data.length} Potential ${data.length === 1 ? 'Risk' : 'Risks'}`;
    };

    return (
        <>
            <div className={classes.header}>
                <FormSectionTitle
                    title={getHelperText()}
                    className={classes.title}
                    variant={'h4'}
                    icon={
                        isOpportunity ? (
                            <TrendingUpIcon
                                className={`${classes.icon} ${classes.opportunityText}`}
                            />
                        ) : (
                            <ErrorIcon className={`${classes.icon} ${classes.riskText}`} />
                        )
                    }
                />
            </div>
            <div className={classes.cardWrapper}>
                {data.map((item, key) => (
                    <Card
                        className={`${classes.cardRoot} ${
                            isOpportunity ? classes.opportunity : classes.risk
                        }`}
                        variant="outlined">
                        <CardContent className={`${classes.cardContent}`}>
                            <Typography
                                variant="body1"
                                className={`${
                                    isOpportunity ? classes.opportunityText : classes.riskText
                                } ${classes.wrapper} ${classes.wrapperTextMain}`}
                                variant={'h6'}>
                                {isOpportunity ? item.opportunity_text : item.risk_text}
                            </Typography>
                            {isOpportunity && item.value !== 0 && (
                                <div
                                    className={`${classes.wrapper} ${classes.wrapperTextSecondary}`}>
                                    <div>
                                        <Typography variant="body1">
                                            <span className={classes.predictedValue}>
                                                Predicted value:{' '}
                                            </span>
                                            &pound;{item.value}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                        </CardContent>
                        <CardContent className={classes.cardContent}>
                            <div className={`${classes.wrapper} ${classes.wrapperTextMain}`}>
                                <div>
                                    { item.external_link?.url &&
                                        <Typography variant="body1" className={classes.externalLink}>
                                            <ExternalLink href={item.external_link.url}>
                                                {item.external_link.label}
                                            </ExternalLink>
                                        </Typography>
                                    }
                                </div>
                            </div>

                            {item.action && item.action.completed_at && (
                                <Typography
                                    variant="body2"
                                    className={`${classes.actionedRibbon} ${
                                        isOpportunity ? classes.opportunityBg : classes.riskBg
                                    }`}>
                                    <VfTick />
                                </Typography>
                            )}

                            {item.action && (
                                <div
                                    className={`${classes.wrapper} ${classes.wrapperTextSecondary}`}>
                                    {item.action.completed_at && <Typography variant="body1" color="secondary">Actioned {formatSimpleListDate(item.action.completed_at)}</Typography>}
                                    <Button
                                        variant={`${item.action.completed_at ? "text" : "contained"}`}
                                        color={`${item.action.completed_at ? "secondary" : "tertiary"}`}
                                        onClick={() => handleActionChange((item.action.completed_at ? false : true), item, key)}
                                    >{
                                        item.action.completed_at
                                        ? 'UNDO'
                                        : 'Mark actioned'
                                    }
                                    </Button>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                ))}
            </div>
        </>
    );
};

export default RisksAndOpportunities;
