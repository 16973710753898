import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import MyLayout from '../MyLayout';
import MyTheme from '../MyTheme';
import LoginForm from './LoginForm';
import PageContent from '../AppLayout/PageContent';
import { CONTACT, GUIDE_HELP_URL } from '../constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import Button from '../Components/Buttons/CustomButton';
import { createReplaceVariable } from '../utils';
import background from '../assets/images/background.png';
import topRatedImage from '../assets/images/top-rated-guide-2025.png';
import { END_OF_2025_GUIDE } from '../constants/date';
import tokenManager from '../tokenManager';
import { useHistory } from 'react-router-dom';

const styles = theme => ({
    formContainer: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5),
        },

        '& .MuiGrid-item': {
            [theme.breakpoints.down('sm')]: {
                width:'100%',
                height:'auto',
            },
        },
    },
    loginFormContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('lg')]: {
            paddingRight: 100,
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            padding: 0,
        },
    },
    loginFormContainerNoTopRatedBanner: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            paddingRight: 100,
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    topRatedContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginTop: 10,
        },
    },
    topRatedImage: {
        display: 'block',
        height: '100%',
        width: 'auto',

        [theme.breakpoints.between(965, 1147)]: {
            width: '100%',
            height: 'auto',
            margin: '50px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto',
            maxWidth: '750px',
        },
    },
    points: {
        margin: `0 auto ${theme.spacing(5)}px auto`,
    },
    registerLink: {
        textDecoration: 'none',
    },
    secondSection: {
        margin: `0 auto ${theme.spacing(2)}px auto`,
        textAlign: 'center',

    },
});

const Login = ({ classes, loading, userLogin }) => {
    const history = useHistory()
    const shouldShowGuide2025Banner = new Date() < END_OF_2025_GUIDE

    useEffect(() => {
        if (tokenManager.isTwoFactor()) {
            history.push('/2fa');

            return
        }

        localStorage.clear();
    }, []);

    const handleSubmit = (record) => {
        userLogin({
            username: record.email,
            password: record.password,
        }, "/2fa");
    }

    return (
        <MyLayout theme={MyTheme}>
            <PageContent>
                <Grid container className={classes.formContainer}>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={shouldShowGuide2025Banner ? 6 : 12}
                        className={shouldShowGuide2025Banner ? classes.loginFormContainer : classes.loginFormContainerNoTopRatedBanner}
                    >
                        <LoginForm
                            handleSubmit={handleSubmit}
                            loading={loading}
                        />
                    </Grid>
                    {
                        shouldShowGuide2025Banner &&
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.topRatedContainer}
                        >
                            <a href={GUIDE_HELP_URL}>
                                <img
                                    className={classes.topRatedImage}
                                    src={topRatedImage}
                                    alt={'top rated image'}
                                />
                            </a>
                        </Grid>
                    }
                </Grid>
                <div className={classes.secondSection}>
                    <div className={classes.points}>
                        <Typography variant="body1">
                            <h3><b>Are you managing a firm?</b></h3>
                        </Typography>
                        <Button
                            variant="contained"
                            color="tertiary"
                            href= {createReplaceVariable('https://firms.vouchedfor.co.uk') + '/login'}
                            className={classes.button}
                        >
                            Log in to your firm portal
                        </Button>
                    </div>

                    <Typography variant="body1">
                        To log in, you need to use a modern browser: the latest versions of
                    </Typography>
                    <Typography variant="body1">
                        Chrome, Firefox, Safari, Edge are supported
                    </Typography>
                    <Typography variant="body1">
                        <b>Need help?</b> Call us on {CONTACT.PHONE_NUMBER}
                    </Typography>
                </div>
            </PageContent>
        </MyLayout>
    )
}

const mapStateToProps = ({ admin }) => {
    return {
        loading: admin.loading,
    };
};

export default connect(
    mapStateToProps,
    { userLogin }
)(withStyles(styles)(withWidth()(Login)));
