import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'react-admin';

const useHomePageStyles = makeStyles(theme => ({
    loggedInAs: {
        color: `${theme.palette.error.main} !important`,
    },
    list: {
        '& li::marker': {
            margin: 'auto',
        },
        '& li': {
            paddingTop: 0,
            paddingBottom: 0,
            '&:not(:first-child)': {
                '&:before': {
                    content: '""',
                    display: 'inline-block',
                    borderRadius: '50%',
                    position: 'absolute',
                },
            color: 'white',
            },
            '&:not(:last-child)': {
                paddingRight: 10,
            },
        },
    },
    listItem: {
        fontSize: theme.typography.fontSize,
        position: 'relative',

        '& > p': {
            margin: 0,
        },
    },
}));

const useStyles = makeStyles(theme => ({
    loggedInAs: {
        color: `${theme.palette.error.main} !important`,
    },

    list: {
        '& li': {
            '&:not(:first-child)': {
                paddingLeft: theme.spacing(2),
                '&:before': {
                    content: '""',
                    display: 'inline-block',
                    width: theme.spacing(1) / 2,
                    height: theme.spacing(1) / 2,
                    borderRadius: '50%',
                    background: theme.palette.primary.main,
                    position: 'absolute',
                    top: 16,
                    left: 0,
                    margin: `0 6px`,
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 5,
            marginTop: 10,
        },
    },
    listItem: {
        fontSize: theme.typography.fontSize,
        position: 'relative',

        '& > p': {
            margin: 0,
        },
    },
}));

const HeaderProfessionalDetails = (
    {
        isImpersonating,
        firstName,
        contactable,
        plan,
        verifiedSince,
        isHomePage= false,
    }) => {
    const homePageStyles = useHomePageStyles();
    const defaultStyles = useStyles();
    const classes = isHomePage ? homePageStyles : defaultStyles;
    const { permissions } = usePermissions();

    return (
        <ul className={classes.list}>
            {isImpersonating && (
                <li className={cn(classes.loggedInAs, classes.listItem)}>
                    <Typography variant="caption">
                        {`You are currently logged in as: `}
                        <b>{firstName}</b>
                    </Typography>
                </li>
            )}
            {!permissions?.elevation_private_mode &&
                <>
                    <li className={classes.listItem}>
                        {contactable ? (
                            <Typography variant="caption">Available for contact</Typography>
                        ) : (
                            <Typography color="error" variant="caption">
                                Unavailable For Contact
                            </Typography>
                        )}
                    </li>
                    {plan && (
                        <li className={classes.listItem}>
                            <Typography variant="caption">{`${plan} Plan`}</Typography>
                        </li>
                    )}
                    <li className={classes.listItem}>
                        {verifiedSince ? (
                            <Typography variant="caption">
                                {`Verified Since ${moment(verifiedSince).format('DD-MM-YYYY')}`}
                            </Typography>
                        ) : (
                            <Typography color="error" variant="caption">
                                Not Fully Verified
                            </Typography>
                        )}
                    </li>
                </>
            }
        </ul>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        isImpersonating: _.get(basicInfo, 'isImpersonating'),
        contactable: _.get(basicInfo, 'contactable'),
        firstName: _.get(basicInfo, 'first_name'),
        plan: _.get(basicInfo, 'plan'),
        verifiedSince: _.get(basicInfo, 'verified_since'),
    };
};

export default connect(mapStateToProps)(HeaderProfessionalDetails);
