import React from 'react';
import GuideTrackerProgressBarsV2 from './GuideTrackerProgressBarsV2';
import { useQuery } from 'react-admin';
import { isUndefined } from 'lodash';

export default function GuideTrackerForPrivateAdviserV2() {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'dashboard/guide-tracker',
    });

    if (loading || error || isUndefined(data?.hasPublicProfile)) {
        return null;
    }

    return (
        <GuideTrackerProgressBarsV2 data={data}/>
    );
}