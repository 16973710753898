import React from 'react';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import { FormSectionTitle } from '../Components/Typography/Headlines';
import { ExternalLink } from '../Components/Links';
import ProfileImage from './Components/ProfileImage';
import LanguagesGrid from '../Languages/Components/LanguagesGrid';
import { SUPPORT_URL, PROFESSIONAL, GENDER_CHOICES } from '../constants';
import ProfessionalPageWrapper from './Components/ProfessionalPageWrapper';
import AddButton from '../Components/Buttons/AddButton';
import FormLongTextInput from '../Components/Inputs/Form/FormLongTextInput';
import FormDateInput from "../Components/Inputs/Form/FormDateInput";
import FormSelectInput from '../Components/Inputs/Form/FormSelectInput';
import { vfColours } from '../MyTheme';
import { required } from 'react-admin';

const { LEGAL_ADVISER, ACCOUNTANT } = PROFESSIONAL.VERTICAL;

const AboutMeChild = props => {
    const { record, permissions } = props;
    const vertical = _.get(permissions, 'vertical', '');
    const aboutMeHelperText = "In order for your profile to appear in google search results, your about me must have at least 50 words";

    return (
        <div>
            <FormSectionTitle title="About Me" />
            <Typography variant="body1">
                Stuck on what to write?&nbsp;
                <ExternalLink href={SUPPORT_URL.PROFILE.HOW_TO_WRITE_ABOUT_ME}>
                    Read our blog&nbsp;
                </ExternalLink>
                on <b>How To Write The Perfect 'About Me' Section</b>
            </Typography>
            <ProfileImage source="file" record={record} />
            <FormSelectInput
                style={{
                    marginTop: 16,
                    marginBottom: 16,
                }}
                source="gender"
                label="Gender"
                choices={GENDER_CHOICES}
                validate={required()}
                needsDetails={!record.gender}
                helperText={false}
            />
            {!record.gender && <div style={{color: vfColours.grassGreen, position: 'relative', top: -16, left: 8, fontSize: 12}}>To help more consumers find the best adviser for them, which of these best describes you? <a href={'//support.vouchedfor.co.uk/article/dkfn0vxn1g-our-new-gender-filter'} target={'_blank'}>(Why do we ask this?)</a></div>}
            <FormLongTextInput label="About me" source="about" helperText={aboutMeHelperText} />
            {(vertical === ACCOUNTANT || vertical === LEGAL_ADVISER) && (
                <React.Fragment>
                    <FormSectionTitle key="title" title="Languages" />
                    <LanguagesGrid key="grid" {...props} />
                    <AddButton
                        key="button"
                        resource="languages"
                        label="Add language"
                        to={{ pathname: '/languages/create' }}
                    />
                </React.Fragment>
            )}
            {
                record.should_show_approved_field &&
                <FormDateInput label="SJP Approved" source="approved_on_date" />
            }
        </div>
    );
};

const AboutMe = props => {
    return (
        <ProfessionalPageWrapper {...props}>
            <AboutMeChild {...props} />
        </ProfessionalPageWrapper>
    );
};

export default AboutMe;
