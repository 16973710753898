import API_KEYS from './constants/api-keys';
import { checkCookiesAndInitMixpanel, isProductionEnv, trackMixpanel } from './utils';

const gTag = key => {
    return {
        track: function (action, options) {
            const { category, label, value, professionalId } = options;

            if (!isProductionEnv()) {
                console.log(
                    `Gtag activated\n` +
                    (category ? `Category: ${category}\n` : '') +
                    (action ? `Action: ${action}\n` : '') +
                    (label ? `Label: ${label}\n` : '') +
                    (value ? `Value: ${JSON.stringify(value)}\n` : '') +
                    (professionalId ? `Professional id: ${JSON.stringify(professionalId)}\n` : '')
                );
            }

            window.gtag('event', action, {
                'event_category': category,
                'event_label': label,
                'value': value,
                'professional_id': professionalId,
            });
        },
        pageView: function(pathname) {
            if (pathname) {
                return window.gtag('config', key, { page_path: pathname });
            }
        },
    };
}

const GoogleAnalytics = key => {
    return {
        activate() {
            /** google's analytics related code **/
            (function(i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r;

                if (!i[r]) {
                    i[r] = function() {
                        (i[r].q = i[r].q || []).push(arguments);
                    };

                    i[r].l = 1 * new Date();
                }

                a = s.createElement(o);
                a.setAttribute('type', 'text/plain')
                a.setAttribute('data-cookiescript', 'accepted')
                m = s.getElementsByTagName(o)[0];
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(
                window,
                document,
                'script',
                'https://www.google-analytics.com/analytics.js',
                'ga'
            );

            window.ga('create', key, 'auto');
            window.ga('send', 'pageview');
        },
        track(action, options) {
            const { category, label, value } = options;

            window.ga('send', 'event', category, action, label, value);

            if (!isProductionEnv()) {
                console.log(
                    `Analytics activated\n` +
                        (category ? `Category: ${category}\n` : '') +
                        (action ? `Action: ${action}\n` : '') +
                        (label ? `Label: ${label}\n` : '') +
                        (value ? `Value: ${JSON.stringify(value)}\n` : '')
                );
            }
        },
        pageView(pathname) {
            window.ga('send', 'pageview', pathname);
        },
    };
};

const linkedinPixel = key => {
    return {
        activate: function() {
            window._linkedin_data_partner_ids =
                window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(key);
            (function() {
                var s = document.getElementsByTagName('script')[0];
                var b = document.createElement('script');
                b.type = 'text/plain';
                b.setAttribute('data-cookiescript', 'accepted');
                b.setAttribute('data-cookiecategory', 'targeting');
                b.async = true;
                b.src =
                    'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                s.parentNode.insertBefore(b, s);
            })();
        },
        track: function() {},
        pageView: function() {},
    };
};

const mixpanel = () => {
    return {
        activate: function() {
            checkCookiesAndInitMixpanel();
        },
        track: function(action, option) {
            trackMixpanel(action, option);
        },
        pageView: function() {},
    }
}

const integrations = [
    GoogleAnalytics(API_KEYS.google_analytics_key),
    linkedinPixel(API_KEYS.linkedin_pixel_key),
    gTag(API_KEYS.gtag_key),
    mixpanel(),
];

const integrationsToActivate = [
    GoogleAnalytics(API_KEYS.google_analytics_key),
    linkedinPixel(API_KEYS.linkedin_pixel_key),
    mixpanel(),
];

export function activate() {
    integrationsToActivate.forEach(integration => integration.activate());
}

export function track(action, options) {
    integrations.forEach(integration => integration.track(action, options));
}

export function pageView(pathname) {
    integrations.forEach(integration => integration.pageView(pathname));
}
