import { START_IMPERSONATE, END_IMPERSONATE } from './types';
import { createBasicInfo } from '../utils';
import { LOCAL_STORAGE } from '../constants';
const { BASIC_INFO, LEXIKTOKEN, INITIAL_TOKEN, IS_IMPERSONATING } = LOCAL_STORAGE;

export function impersonate(basicInfo) {
    const initialToken = localStorage.getItem(LEXIKTOKEN);
    localStorage.setItem(INITIAL_TOKEN, initialToken);
    const formattedBasicInfo = createBasicInfo({data: basicInfo.data.basicInfo});
    localStorage.setItem(BASIC_INFO, JSON.stringify(formattedBasicInfo));
    localStorage.setItem(IS_IMPERSONATING, true);
    localStorage.setItem(LEXIKTOKEN, basicInfo.data.token);
    return {
        type: START_IMPERSONATE,
        basicInfo: formattedBasicInfo.data,
    };
}

export function endImpersonate(id) {
    return {
        type: END_IMPERSONATE,
        payload: id,
    };
}
