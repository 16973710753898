import React from 'react';
import DialogBase from '../Components/Dialogs/DialogBase';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { palette } from '../MyTheme';
import { useDataProvider } from 'react-admin';
import { fetchBasicInfo } from '../state/actions';
import { useDispatch } from 'react-redux';
import TAndC from './TAnC';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& > .MuiPaper-root': {
            maxWidth: '1200px',
            minHeight: '800px',
        },
    },
    dialogTitle: {
        fontSize: '2rem',
        marginLeft: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
    },
    tAndCWrapper: {
        maxHeight: '500px',
        overflow: 'auto',
        padding: '1rem',
        border: '1px solid lightgrey',
    },
}));

const TAndCDialogModal = ({ isAutoBuilt, professionalId }) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    const handleCallbackClick = async () => {
        await dataProvider('UPDATE', 'professional', {
            data: {
                id: professionalId,
                accepted_terms_and_conditions_date: new Date(),
            },
        });
        dispatch(fetchBasicInfo());
    };
    return (
        <DialogBase
            title={
                <Typography className={classes.dialogTitle}>
                    {!isAutoBuilt ? "We've updated our terms" : 'Our terms'}
                </Typography>
            }
            modalWidth={1200}
            open={true}
            className={classes.dialog}
            SaveButtonProps={{
                label: 'Accept',
                onClick: handleCallbackClick,
            }}
            hideClose>
            <>
                {!isAutoBuilt && (
                    <Typography>
                        Our terms have been updated to reflect changes to our newest service,
                        <a
                            className={classes.link}
                            target="_blank"
                            href="https://professionals.vouchedfor.co.uk/elevation">
                            &nbsp;Elevation
                        </a>
                        , as well as reflecting minor updates to our Verified service.
                    </Typography>
                )}
                <Typography>
                    <strong>Please confirm you accept the terms of use by clicking below</strong>
                </Typography>

                <div data-test={'terms-and-conditions-popup-content'} className={classes.tAndCWrapper}>
                    <TAndC />
                </div>
            </>
        </DialogBase>
    );
};

export default TAndCDialogModal;
