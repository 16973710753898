import { createMuiTheme } from "@material-ui/core/styles";
import theme from '@material-ui/core/styles/defaultTheme';

export const accessibilityBgColor = '#000';

export const vfColours = {
    navyBlue: '#022D4F',
    mintGreen: '#4BC1CA',
    brightBlue: '#4282F3',
    grassGreen: '#1FAF83',
    cherryRed: '#E43B58',
    darkWarmGrey: '#474444',
    paleMintGreen: '#DBF3F5',
    paleBlue: '#DDE9FF',
    paleGrassGreen: '#E2F5EF',
    palePink: '#F3D3C7',
    midWarmGrey: '#C1B8B3',
    paleWarmGrey: '#EEEBE9',
    white: '#fff',
    blueCool: '#DAF3F5',
};

export const palette = {
    primary: {
        light: vfColours.white,
        main: vfColours.navyBlue,
        dark: vfColours.darkWarmGrey,
        contrastText: vfColours.white,
    },
    secondary: {
        light: vfColours.paleMintGreen,
        main: vfColours.mintGreen,
        dark: vfColours.grassGreen,
        contrastText: vfColours.paleGrassGreen,
    },
    tertiary: {
        light: vfColours.paleBlue,
        main: vfColours.brightBlue,
        contrastText: vfColours.paleWarmGrey,
        dark: vfColours.midWarmGrey,
    },
    error: {
        light: vfColours.palePink,
        main: vfColours.cherryRed,
        contrastText: vfColours.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
};

export const fonts = {
    reviewFont: 'Latin Modern Mono',
};

export const customStyles = {
    transition: 'all cubic-bezier(0.0, 0, 0.2, 1) 200ms',
};

const myTheme = createMuiTheme({
    ...theme,
    accessibilityBgColor,
    palette,
    fonts,
    typography: {
        fontFamily: [
            'Nunito',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
        smallFontSize: 12,
        fontSize: 14,
    },
    overrides: {
        MuiAppBar: {
            root: {
                boxShadow: 'none',
            },
            colorSecondary: {
                color: palette.primary.contrastText,
            },
        },
        MuiTypography: {
            root: {
                width: 'auto !important',
                marginTop: '8px',
                marginBottom: '8px',
            },
            body1: {
                fontSize: '1.1rem',
            },
            h1: {
                fontSize: '56px',
                fontWeight: 700,
                marginBottom: '16px',

                [theme.breakpoints.down('sm')]: {
                    fontSize: '40px',
                },
            },
            h3: {
                fontSize: '40px',
                fontWeight: 700,
            },
            h2: {
                fontWeight: 700,
            },
            h4: {
                fontSize: '24px',
                fontWeight: 700,
            },
            h5: {
                color: palette.secondary.main,
                fontSize: '16px',
                marginTop: '24px',
                marginBottom: '14px',
                fontWeight: 700,
            },
            h6: {
                paddingTop: '24px',
                paddingBottom: 0,
                fontWeight: 700,
            },
            subtitle1: {
                marginTop: '24px',
                marginBottom: '14px',
                fontSize: '18px',
                fontWeight: 700,
                '& > div > span': {
                    '@media (max-width: 1024px)': {
                        opacity: 1,
                        fontSize: '16px',
                    },
                },
            },
            caption: {
                display: 'block',
                fontSize: '12px',
            },
            gutterBottom: {
                marginBottom: '16px',
            },
        },
        MuiPaper: {
            rounded: {
                padding: '24px',
                '& .MuiPickersCalendarHeader-switchHeader': {
                    '& .MuiPickersCalendarHeader-transitionContainer': {
                        height: '40px',
                    },
                },
            },
            root: {
                boxShadow: 'none',
                '& .mui-fixed': {
                    '@media (max-width: 767px)': {
                        display: 'none',
                    },
                },
            },
            
        },
        MuiMenu: {
            paper: {
                borderRadius: "1em",
                padding: 0,
                "& .MuiListItem-root": {
                  fontWeight: 700,
                  color: palette.primary.main,
                  borderBottom: `1px solid ${palette.tertiary.contrastText}`,
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                  "&:hover": {
                    color: palette.tertiary.main,
                    backgroundColor: palette.tertiary.light,
                  },
                  "&.Mui-selected": {
                    color: palette.tertiary.main,
                    backgroundColor: palette.tertiary.light,
                    "&:hover": {
                      color: palette.tertiary.main,
                      backgroundColor: palette.tertiary.light,
                    },
                  },
                },
                
              },
        },
        MuiCardContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
                ' & > div ': {
                    alignItems: 'center',
                },
                '@media (max-width: 599px)': {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
        },
        MuiDivider: {
            root: {
                marginBottom: '24px',
            },
        },
        MuiFormControl: {
            root: {
                width: '100% !important',
                marginTop: 4,
            },
        },
        MuiInputBase: {
            root: {
                width: '100%',
                maxWidth: 360,
                borderRadius: '2rem',
                backgroundColor: '#fff',
                color: palette.primary.main,
            },
            multiline: {
                maxWidth: 800,
            },
            fullWidth: {
                maxWidth: '100%',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: '2rem',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.secondary.main,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.secondary.main,
                },
            },
            input: {
                padding: '14px 18.5px',
            },
        },
        MuiFilledInput: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                },
                '&.Mui-focused': {
                    backgroundColor: 'transparent'
                },
            },
        },
        MuiTableRow: {
            head: {
                height: '24px',
            },
        },
        MuiToolbar: {
            gutters: {
                position: 'relative !important',
                '@media (min-width: 767px)': {
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '24px',
                    paddingTop: '16px',
                },
                paddingLeft: 0,
                paddingRight: 0,
                MuiButton: {
                    marginLeft: 0,
                },
                '& button:first-child': {
                    marginLeft: 0,
                },
            },
            root: {
                backgroundColor: 'unset !important',
                '@media (max-width:599px)': {
                    '&:last-of-type': {
                        position: 'relative!important',
                        justifyContent: 'center',
                    },
                },
                '& .ra-delete-button': {
                    display: 'none',
                },
                '& .MuiTablePagination-input': {
                    width: 'auto',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: palette.secondary.main,
            },
        },
        MuiDrawer: {
            paper: {
                '@media (min-width:1280px)': {
                    marginTop: '0!important',
                    paddingTop: '24px!important',
                },
            },
        },
        MuiTable: {
            root: {
                width: '100% !important',
                margin: '24px 0',
            },
        },
        MuiTableCell: {
            root: {
                fontSize: '14px',
                '& .MuiButton-label > svg': {
                    display: 'none',
                },
            },
            head: {
                cursor: 'pointer',
                color: palette.primary.dark,
                '& span:hover': {
                    transition: customStyles.transition,
                    color: palette.secondary.main,
                },
                fontWeight: 600,
            },
        },
        MuiTablePagination: {
            select: {
                padding: '10px 20px 10px 10px!important',
            },
            selectIcon: {
                top: '50%',
                transform: 'translateY(-50%)',
            },
            toolbar: {
                color: palette.secondary.main,
                '& svg': {
                    color: palette.secondary.main,
                },
                '& .MuiButtonBase-root': {
                    color: palette.secondary.main,
                },
            }
        },
        MuiButton: {
            root: {
                fontSize: '14px',
                borderRadius: '2rem',
                fontWeight: 700,
                boxShadow: 'none !important',
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            label: {
                '& > a': {
                    textDecoration: 'none',
                    color: palette.primary.contrastText
                },
            },
            sizeSmall: {
                fontSize: '14px',
                '& span': {
                    paddingLeft: 0,
                },
            },
            containedPrimary: {
                backgroundColor: palette.tertiary.main,
                '&:hover': {
                    backgroundColor: palette.tertiary.dark,
                },
                '& svg': {
                    display: 'none',
                },
            },
            textPrimary: {
                '& svg': {
                    padding: '0 4px',
                },
            },
            iconSizeLarge: {
                '& > *:first-child': {
                    fontSize: 30,
                }
            },
        },
        MuiSwitch: {
            root: {
                '&$checked': {
                    color: palette.secondary.dark + '!important',
                    '&$bar': {
                        backgroundColor: palette.secondary.dark + '!important',
                    },
                },
            },
        },
        MuiChip: {
            root: {
                backgroundColor: palette.secondary.main,
            },
            clickable: {
                '&:hover': {
                    backgroundColor: palette.secondary.light,
                },
            },
        },
        MuiTab: {
            root: {
                fontSize: '16px',
                opacity: 1,
            },
        },
        MuiFormHelperText: {
            root: {
                color: palette.error.main,
                lineHeight: 'initial',
            },
        },
        MuiListItem: {
            gutters: {
                '@media (max-width: 767px)': {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
        },
        MuiListItemText: {
            root: {
                '@media (max-width: 767px)': {
                    paddingRight: 0,
                },
            },
        },
        MuiSkeleton: {
            pulse: {
                animation: 'pulse 1.5s ease-in-out 0.5s infinite',
            },
        },
        MuiPickersModal: {
            dialogRoot: {
                '& .MuiTypography-root': {
                    margin: 0,
                },
            },
        },
        MuiPickersCalendarHeader: {
            daysHeader: {
                '& .MuiTypography-root': {
                    margin: '0 2px',
                    width: '36px !important',
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.error.main,
                boxShadow: theme.shadows[1],
                fontSize: '0.9rem',
            },
        },
        MuiFormLabel: {
            root: {
                color: palette.primary.main
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(18.5px, 16.5px) scale(1);'
            },
            shrink: {
                paddingLeft: '4px'
            }
        }

    },
});

export default myTheme;
