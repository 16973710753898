import React, { useEffect, useState } from 'react';
import myTheme from '../MyTheme';
import MyLayout from '../MyLayout';
import tokenManager from '../tokenManager';
import PageContent from '../AppLayout/PageContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import { Link, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Email } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { useAuthProvider, useLogout } from 'react-admin';
import { THIRTY_SECONDS } from '../constants/time';

const styles = theme => ({
    gridContainer: {
        backgroundColor: '#4BC1CA',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5),
        },

        '& .MuiGrid-item': {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    },

    authFormContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('lg')]: {
            paddingRight: 100,
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            padding: 0,
        },
        textAlign: 'center',
    },

    card: {
        width: 464,
        padding: 20,
        background: '#a5dee2'
    },

    description: {
        margin: 0,
        paddingTop: 20,
        paddingBottom: 20,
    },

    progress: {
        marginLeft: 4,
    },

    verify: {
        marginTop: 10,
        color: theme.palette.common.white,
        background: '#4282F3',
        width: "100%",
        '&:hover': {
            backgroundColor: '#C1B8B3',
        }
    },

    resendContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },

    resendLink: {
        cursor: 'pointer',
        color: theme.palette.brightBlue,
        fontWeight: 700,
        textDecoration: 'none',
    },

    logoutLink: {
        color: theme.palette.brightBlue,
        fontWeight: 700,
        textDecoration: 'none',
    },

    disabledLink: {
        color: theme.palette.grey["500"],
        cursor: 'not-allowed',
        fontWeight: 700,
        textDecoration: 'none',
    },

    pr4: {
        paddingRight: 4,
    },

    resendMessage: {
        marginTop: 10,
    }
})

const TwoFa = ({ classes }) => {
    const history = useHistory()
    const logout = useLogout()
    const auth = useAuthProvider()

    const [otp, setOtp] = useState('')
    const [authError, setAuthError] = useState('')
    const [resendMessage, setResendMessage] = useState('')
    const [resendError, setResendError] = useState('')
    const [canResend, setCanResend] = useState(true)
    const [isTwoFa, setIsTwoFa] = useState(true)

    useEffect(() => {
        /**
         * Checks the Token has the 2FA role, and it's still a valid token (expiry)
         * Simpler than calling the Refresh token function, since API doesn't have this
         * functionality.
         */
        setIsTwoFa(tokenManager.isTwoFactor())
    }, [])

    useEffect(() => {
        if (!isTwoFa) {
            history.push('/')
        }
    }, [isTwoFa])

    useEffect(() => {
        if (canResend) {
            return
        }

        setTimeout(() => {
            setCanResend(true)
        }, THIRTY_SECONDS)
    }, [canResend])

    async function handleSubmit(event) {
        event.preventDefault()

        if (otp === '') {
            setIsTwoFa(tokenManager.isTwoFactor())
            setAuthError('You must provide a code')

            return
        }

        setResendMessage('')
        // Send to /auth/mfa-email with Payload {authCode: {code: 00000}}
        const response = await auth.verifyCode(otp)

        if (response?.success) {
            // Authenticated, let's move on!
            history.push('/')

            return
        }

        setAuthError(response?.message)
    }

    async function handleResend(event) {
        event.preventDefault()

        if (!canResend) {
            return
        }

        setResendError('')
        setResendMessage('')

        const response = await auth.resendCode()

        if (!response?.error) {
            setResendMessage(response?.message)
            setCanResend(false)

            return
        }

        setResendError(response.message)
    }

    const Resend = ({ onClick }) => {
        if (canResend) {
            return (
                <Typography variant={'body2'}>
                    <Link
                        onClick={onClick}
                        className={classes.resendLink}
                    >
                        Resend
                    </Link>
                </Typography>
            )
        }

        return (
            <>
                <Typography variant={'body2'} className={classes.disabledLink}>Resend</Typography>
                <CircularProgress size={8} className={classes.progress} />
            </>
        )
    }

    function ResentMessage() {
        if (resendMessage) {
            return (<Alert className={classes.resendMessage} severity="success">{resendMessage}</Alert>)
        }

        if (resendError) {
            return (<Alert className={classes.resendMessage} severity="error">{resendError}</Alert>)
        }

        return null
    }

    return (
        <MyLayout theme={myTheme}>
            <PageContent>
                <Grid
                    container
                    className={classes.gridContainer}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
              >
                    <Grid item className={classes.authFormContainer}>
                        <Card className={classes.card}>
                            <Typography variant={'body1'}>
                                <h2>Two Factor Authentication</h2>

                                <Email />

                                <Typography variant={'body1'} className={classes.description}>
                                    In order to keep your account secure, an authentication code has
                                    been sent to the email address you used to log in. Please enter
                                    that code below.
                                </Typography>

                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    autoComplete={'off'}
                                >
                                    <TextField
                                        variant={'outlined'}
                                        label={'Code'}
                                        error={!!authError}
                                        helperText={authError}
                                        onChange={(event) => setOtp(event.target.value)}
                                        value={otp}
                                        required
                                        fullWidth
                                        sx={{ mb: 2, mt: 2 }}
                                    />

                                    <Button type={'submit'} className={classes.verify}>
                                        Verify
                                    </Button>

                                    <div className={classes.resendContainer}>
                                        <Typography variant={'body2'} className={classes.pr4}>
                                            Not received an email?
                                        </Typography>

                                        <Resend onClick={handleResend} />
                                    </div>

                                    <div>
                                        <Typography variant={'body2'}>
                                            <Link className={classes.logoutLink} onClick={logout}>Logout</Link>
                                        </Typography>
                                    </div>

                                    <ResentMessage />
                                </form>
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </PageContent>
        </MyLayout>
    );
};

export default withStyles(styles)(TwoFa)