import cn from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    root: {
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
        },
    },
    needsDetails: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.dark,
            border: '2px solid'
        }
    }
}));

const useOutlinedStyle = (customClass, needsDetails = false) => {
    const classes = useStyle();

    return cn({ [classes.root]: true, [customClass]: Boolean(customClass), [classes.needsDetails]: needsDetails });
};

export default useOutlinedStyle;
