import { cloneDeep, drop, get, values } from 'lodash';
import { API_KEYS, LOCAL_STORAGE, PROFESSIONAL, ROOT_DOMAIN } from './constants';
import HighlightShare from 'highlight-share';
import * as linkedInSharer from './Helpers/customLinkedInSharer';
import * as twitterSharer from './Helpers/customTwitterSharer';
import * as facebookSharer from './Helpers/customeFacebookSharer';
import decode from 'jwt-decode';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module';

export function formatDate(date) {
    const pad = '00';
    const yyyy = date.getFullYear().toString();
    const MM = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
}

export function formatSimpleListDate(date) {
    return new Date(date).toLocaleDateString();
}

export function getDate() {
    const now = new Date();
    const day = ('0' + now.getDate()).slice(-2);
    const month = ('0' + (now.getMonth() + 1)).slice(-2);

    return `${now.getFullYear()}-${month}-${day}`;
}

export function getMonth() {
    return new Date().toLocaleString('en-us', { month: 'long' });
}

export function getDateFromObject(object) {
    const dateArray = drop(values(object), 1);
    const day = dateArray[7] + dateArray[8];
    const month = dateArray[4] + dateArray[5];
    const year = dateArray[1] + dateArray[2];

    return `20${year}-${month}-${day}`;
}

export function isFinancialAdviserCheck(basicInfo) {
    return (
        get(basicInfo, ['permissions', 'vertical'], '') ===
        PROFESSIONAL.VERTICAL.FINANCIAL_ADVISER
    );
}

export function getAddServicePayload(params) {
    const { attribute, selectedChild, ...rest } = params;

    if (selectedChild) {
        return {
            attribute: selectedChild,
            ...rest,
        };
    }

    return { attribute, ...rest };
}

export function getAccountSettingsPayload(params) {
    return {
        email: params.data.email,
        emailCc: params.data.email_cc,
        phoneDirect: params.data.phone_direct,
        plainPassword: params.data.plainPassword,
        securityQuestion: params.data.security_question,
        securityAnswer: params.data.security_answer,
    };
}

export const MULTIPLE_SHARE_SELECTION_ID = 'multiple_shareable';

export function initSocialShare() {
    const selectionShare = HighlightShare({
        selector: `#${MULTIPLE_SHARE_SELECTION_ID}`,
        sharers: [facebookSharer, twitterSharer, linkedInSharer],
        transformer: raw => raw.trim().replace(/\\n+/g, '\n'),
    });

    if (!window.matchMedia || !window.matchMedia('(pointer: coarse)').matches) {
        selectionShare.init();
    }
}

export function closeSharePopover() {
    const sharePopoverItems = document.getElementsByClassName('highlight-share-popover');

    if (sharePopoverItems.length > 0) {
        sharePopoverItems.forEach(item => (item.style.cssText = 'display: none'));
    }
}

export function onMatchingRoute(routes) {
    const match = new RegExp('/(' + routes.join('|') + ')');

    return match.test(window.location.pathname);
}

export function copyToClipboard(string) {
    if (!string) return;

    const textContainer = document.createElement('textarea');

    textContainer.innerText = string;
    document.body.appendChild(textContainer);
    textContainer.select();
    document.execCommand('copy');
    textContainer.remove();
}

export function truncateText(text, limit) {
    let chunk = text.trim();

    if (chunk.length > limit - 2) {
        chunk = chunk.slice(0, limit - 3).trim() + '\u2026';
    }

    return `\u201c${chunk}\u201d`;
}

function openPopupWindow(url) {
    window.open(
        url,
        '_blank',
        'height=440,location=no,menubar=no,scrollbars=no,status=no,toolbar=no,width=640',
    );
}

export function getAdviserReviewsUrl(id = null) {
    const basicInfo = localStorage.getItem(LOCAL_STORAGE.BASIC_INFO);

    if (!basicInfo) return;

    const json = JSON.parse(basicInfo);
    const permalink = get(json, ['data', 'permalink', 'url'], null);

    return permalink ? `${ROOT_DOMAIN}/${permalink}/reviews${id ? '/#review' + id : ''}` : '';
}

export function shareToTwitter(text, id = null) {
    const content = truncateText(text, 120);
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(content)}`;

    return openPopupWindow(`${shareUrl}&url=${encodeURIComponent(getAdviserReviewsUrl(id))}`);
}

export function shareToLinkedIn(id = null) {
    return openPopupWindow(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            getAdviserReviewsUrl(id),
        )}`,
    );
}

export function shareToFacebook(text, id = null) {
    return openPopupWindow(
        `https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(
            `\u201c${text}\u201d`,
        )}&u=${encodeURIComponent(getAdviserReviewsUrl(id))}`,
    );
}

export function isIE11() {
    return !!window.MSInputMethodContext && !!document.documentMode;
}

export function formatPlural(count, singular, plural) {
    const number = Number(count);

    return number === 1 ? singular : plural;
}

export function featureToggle(numerator, denominator) {
    return numerator % denominator === 0;
}

export function formatCurrency(number) {
    return `£ ${Number(parseInt(number).toFixed(2)).toLocaleString('en-US')}`;
}

export function getFeeInsightsGraphDummyData() {
    return {
        fixed_fees_graph: [
            { professional_count: 58, group: '£0-249' },
            { professional_count: 5, group: '£250-499' },
            { professional_count: 24, group: '£500-749' },
            { professional_count: 20, group: '£750-999' },
            { professional_count: 1, group: '£1000-1249' },
            { professional_count: 2, group: '£1250-1499' },
            { professional_count: 17, group: '£1500-1749' },
            { professional_count: 1, group: '£2000-2249' },
        ],
        initial_charge_graph: [
            { professional_count: 15, group: '<0.4%' },
            { professional_count: 1, group: '0.5% - 0.9%' },
            { professional_count: 19, group: '1% - 1.4%' },
            { professional_count: 5, group: '1.5% - 1.9%' },
            { professional_count: 27, group: '2% - 2.4%' },
            { professional_count: 2, group: '2.5% - 2.9%' },
            { professional_count: 59, group: '3% - 3.4%' },
            { professional_count: 8, group: '>3.5%' },
        ],
        ongoing_charge_graph: [
            { professional_count: 3, group: '<0.4%' },
            { professional_count: 42, group: '0.5% - 0.9%' },
            { professional_count: 89, group: '1% - 1.4%' },
            { professional_count: 2, group: '1.5% - 1.9%' },
        ],
    };
}

export function randomNumber(minVal, maxVal) {
    const val = minVal + Math.random() * (maxVal - minVal);

    return Math.round(val);
}

export function createReplaceVariable(variableName) {
    if (process.env.NODE_ENV === 'production') {
        if (variableName.includes('REPLACEME')) {
            throw new Error(variableName + ' missing from deploy env.');
        }
        return variableName;
    } else {
        return process.env[variableName];
    }
}

export function getApplicationEnvironment() {
    return createReplaceVariable('prod');
}

function getApplicationFeatureFlags() {
    return createReplaceVariable('mixpanel-track-pages,guide-tracker');
}

export function isProductionEnv() {
    return getApplicationEnvironment() === 'prod';
}

export function createBasicInfo(basicInfoResponse) {
    const basicInfo = cloneDeep(basicInfoResponse);

    basicInfo.data.is_logged_in = true;

    return basicInfo;
}

export function getLastMonthName() {
    const date = new Date();
    const currentMonth = date.getMonth();
    const monthIndex = (currentMonth + 11) % 12;
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    return months[monthIndex];
}

export const featureSwitch = (feature, checkProfessional = true, checkFirm = true) => {
    const basicInfoString = localStorage.getItem(LOCAL_STORAGE.BASIC_INFO);
    const basicInfo = JSON.parse(basicInfoString);
    const professional_features = basicInfo?.data?.permissions?.professional_features;
    const firm_features = basicInfo?.data?.permission?.firm_features;

    if (
        (checkProfessional && professional_features?.includes(feature)) ||
        (checkFirm && firm_features?.includes(feature))
    ) {
        return true;
    }
    return false;
};

export const DRIVER_STRENGTHS = [
    {
        driver_id: 1,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 44,
        firmAvg: 80,
    },
    {

        driver_id: 2,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 65,
        firmAvg: 75,
    },
    {
        driver_id: 3,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 70,
        firmAvg: 80,
    },
];
export const DRIVER_AREAS_FOR_IMPROVEMENT = [
    {
        driver_id: 4,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 80,
        firmAvg: 45,
    },
    {
        driver_id: 5,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 68,
        firmAvg: 62,
    },
    {
        driver_id: 10,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 75,
        firmAvg: 65,
    },
];

export function insufficientDriverData(num) {
    if (num?.nr_of_missing_drivers > 0) {
        return `You're missing data for ${num.nr_of_missing_drivers} ${num.nr_of_missing_drivers == 1 ? 'driver' : 'drivers'}, gather more reviews now to compare your performance across more drivers`;
    } else if (num?.min_reviews_to_show_data > 0) {
        return `Not enough review data, you need ${num.min_reviews_to_show_data} more review(s)`;
    }
}


export const formatRange = (range) => {
    return { to: range.to?.toLocaleDateString().slice(0, 10), from: range.from?.toLocaleDateString().slice(0, 10) };
};

export const showWidget = () => {
    switch (true) {
        case (!API_KEYS.vf_2023_widget_launch_date):
            return true;
        case (new Date().toJSON().slice(0, 10) >= API_KEYS.vf_2023_widget_launch_date):
            return false;
        default:
            return true;
    }
};

export const showNewRepTool = () => {
    switch (true) {
        case (!API_KEYS.vf_2023_widget_launch_date):
            return false;
        case (new Date().toJSON().slice(0, 10) >= API_KEYS.vf_2023_widget_launch_date):
            return true;
        default:
            return false;
    }
};


export const parsedLexiktoken = () => {
    const { LEXIKTOKEN } = LOCAL_STORAGE;
    const lexiktoken = localStorage.getItem(LEXIKTOKEN);

    try {
        if (lexiktoken.startsWith('{')) {
            return JSON.parse(lexiktoken);
        } else {
            return decode(lexiktoken);
        }
    } catch (e) {
        return null
    }
};

export const isImpersonator = () => {
    const lexiktoken = parsedLexiktoken();

    return !!lexiktoken?.impersonatorId;
};

export const hasApplicationFeature = (featureFlag) => {
    const applicationFeatureFlags = getApplicationFeatureFlags().split(',');
    let hasFeature = false;

    applicationFeatureFlags.forEach(flag => {
        if (flag === featureFlag) {
            hasFeature = true;
        }
    });

    return hasFeature;
};

const MIXPANEL_TRACK_PAGES = 'mixpanel-track-pages';
const initialiseMixpanel = () => {
    if (!hasApplicationFeature(MIXPANEL_TRACK_PAGES) || isImpersonator()) {
        return;
    }
    try {
        mixpanel.init(API_KEYS.mixpanel_project_token, { track_pageview: 'url-with-path' });
        if (localStorage.getItem('basicInfo')) {
            mixpanel.identify(JSON.parse(localStorage.getItem('basicInfo')).data.id);
        }
    } catch (e) {
        console.log(e);
    }
};

export const checkCookiesAndInitMixpanel = () => {
    if (!hasSelectedPerformanceCookies()) {
        return;
    }
    initialiseMixpanel();
};

const hasSelectedPerformanceCookies = () => {
    const cookieState = window.CookieScript.instance.currentState();
    if (cookieState?.action !== 'accept') {
        return false;
    }

    return cookieState['categories'].includes('performance');
};
window.addEventListener('CookieScriptCategory-performance', function() {
    initialiseMixpanel();
});

export const trackMixpanel = (eventName, props) => {
    if (!hasApplicationFeature(MIXPANEL_TRACK_PAGES) || isImpersonator()) {
        return false;
    }

    try {
        mixpanel.track(eventName, props);
    } catch (e) {
        console.log('Mixpanel not configured')
    }
}

export const getGuideYear = () => {
    return (new Date()).getFullYear() + 1;
}
