import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { makeStyles } from '@material-ui/core/styles';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { vfColours } from '../../../../MyTheme';

const useStyles = makeStyles(theme => ({
    circularProgress: {
        position: 'relative',
        display: 'inline-flex',
    },
    circularProgressWithText: {
        marginInline: 'auto',
        maxWidth: 'fit-content',
    },
    bottom: {
        color: vfColours.paleBlue,
    },
    top: {
        color: theme.palette.primary.main,
        position: 'absolute',
        left: 0,
    },
    progressData: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    goPublicButton: {
        height: '1.5rem',
        marginTop: 2,
    },
    checkIcon: {
        stroke: theme.palette.primary.main,
        strokeWidth: 2,
    },
}));
export default function CircularProgressWithTextInside({ percentage, text }) {
    const classes = useStyles();

    return (
        <div className={classes.circularProgressWithText}>
            <Box className={classes.circularProgress}>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    thickness={4}
                    size={'4rem'}
                    className={classes.bottom}
                />
                <CircularProgress
                    variant="determinate"
                    value={Math.min(100, percentage)}
                    thickness={4}
                    size={'4rem'}
                    className={classes.top}
                />
                <Box className={classes.progressData}>
                    {
                        (percentage < 100) ?
                            (<Typography variant={'body1'}><b>{text}</b></Typography>)
                            : (
                                <CheckRoundedIcon className={classes.checkIcon} fontSize={'large'}/>
                            )
                    }
                </Box>
            </Box>
        </div>
    );
}

export const CircularProgressWithIconInside = ({ value }) => {
    const classes = useStyles();

    return (
        <div className={classes.circularProgressWithText}>
            <Box className={classes.circularProgress}>
                <CircularProgress variant="determinate" value={value} thickness={4} size={'4rem'}/>
                <Box className={classes.progressData}>
                    <ClearRoundedIcon className={classes.checkIcon} fontSize={'large'}/>
                </Box>
            </Box>
        </div>
    );
};