import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../Components/Buttons/CustomButton';
import { vfColours } from '../../../../MyTheme';
import Grid from '@material-ui/core/Grid';
import { useQuery } from 'react-admin';
import GuideTrackerProgressBars from './GuideTrackerProgressBars';
import _ from 'lodash';

export const GUIDE_AVERAGE_RATING_REQUIREMENT = 4.5;
export const GUIDE_REVIEW_COUNT_REQUIREMENT = 10;
export const GUIDE_TRACKER_FEATURE_SWITCH_OPTION = 'guide-tracker';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: vfColours.blueCool,
        paddingBottom: 20,
        paddingTop: 10,
        marginBottom: 10,
    },
    container: {
        justifyContent: 'center',
    },
    contentWrapper: {
        maxWidth: '1100px',
        alignSelf: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    ctaButton: {
        marginTop: '2rem',
        marginInline: 'auto',
        maxWidth: 'fit-content',
    },
    button: {
        width: '180px',
    }
}));

export default function GuideTracker() {
    const classes = useStyles();

    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'dashboard/guide-tracker',
    });

    if (loading || error) {
        return null;
    }

    if (_.isUndefined(data?.hasPublicProfile)) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.container} spacing={1}>
                <div className={classes.contentWrapper}>
                    <GuideTrackerProgressBars data={data}/>
                    <div className={classes.ctaButton}>
                        {data.hasPublicProfile ?
                            (
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    href={'/invite-reviews'}
                                    className={classes.button}
                                >
                                    Invite Reviews
                                </Button>

                            ) : (

                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    href={'/payment'}
                                    className={classes.button}
                                >
                                    Go Public
                                </Button>
                            )}
                    </div>
                </div>
            </Grid>
        </div>
    );
}


