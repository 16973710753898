import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoginResetEmailMessage from './LoginResetEmailMessage';
import { LOCAL_STORAGE } from '../constants';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const { AUTH_ERROR_MESSAGE } = LOCAL_STORAGE;

const ERROR_TYPE = {
    IS_BLOCKED: 'isBlocked',
};

const styles = theme => ({
    container: {
        textAlign: 'center',
    },
    loadingWrapper: {
        marginTop: theme.spacing(2),
    },
    anchor: {
        color: theme.palette.primary.main,
    },
    helpText: {
        marginTop: 25,
        '& .MuiTypography-root':{
            padding: 0,
            margin:0,
            lineHeight:1,
        }
    },
    boldText: {
        fontWeight:'bold',
    }
});

class LoginMessage extends Component {
    state = {
        messageType: null,
        email: null,
    };

    componentDidMount() {
        const error = sessionStorage.getItem(AUTH_ERROR_MESSAGE);

        if (error) {
            const { type, email } = JSON.parse(error);

            this.setState({ email, messageType: type });
        }
    }

    renderMessage = () => {
        const { messageType, email } = this.state;
        const { classes } = this.props;

        if (messageType === ERROR_TYPE.IS_BLOCKED) {
            return <LoginResetEmailMessage email={email} />;
        }

        return (
            <>
                <Typography variant="body1">
                    <Link to="/forgot-password"  className={classes.anchor}>
                        Forgotten your password or yet to set one?
                    </Link>
                </Typography>
                <Typography variant="body1" >
                    Don't have a VouchedFor profile?{' '}
                    <a
                        href="/register"
                        className={classes.anchor}
                    >
                        Sign up for free
                    </a>
                </Typography>
                <div className={classes.helpText}>
                    <Typography className={classes.boldText}>
                        Need help?
                    </Typography>
                    <Typography variant="body1">
                        Call us on 0203 111 0580
                    </Typography>
                </div>
            </>
        );
    };

    render() {
        const { classes } = this.props;

        return <div className={classes.container}>{this.renderMessage()}</div>;
    }
}

export default withStyles(styles)(LoginMessage);
