import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import Button from '../../Components/Buttons/CustomButton';
import { PUBLIC_PROFILE_BENEFITS_V2 } from '../../constants';
import { GUIDE_TRACKER_FEATURE_SWITCH_OPTION } from '../DashboardV3/Components/GuideTracker/GuideTracker';
import { hasApplicationFeature } from '../../utils';
import GuideTrackerForPrivateAdviserV2 from '../DashboardV3/Components/GuideTracker/GuideTrackerForPrivateAdviserV2';
import PrivateDashboardListItemV2 from './Components/PrivateDashboardListItemV2';
import { vfColours } from '../../MyTheme';
import Grid from '@material-ui/core/Grid';
import { track } from '../../analytics';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
    },
    blockContainer: {
        display: 'block',
    },
    upgradeContainer: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
    },
    images: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
    },
    imgImac: {
        width: '100%',
    },
    imgIphone: {
        width: '100%',
    },
    root: {
        backgroundColor: vfColours.blueCool,
        paddingBottom: 20,
        paddingTop: 10,
    },
    container: {
        justifyContent: 'center',
    },
    contentWrapper: {
        maxWidth: '1100px',
        alignSelf: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            padding: `0 0 0 ${theme.spacing(2)}px`,
        },
    },
    ctaButton: {
        maxWidth: 'fit-content',
    },
    button: {
        width: '180px',
    }
}));

const PublicProfileUpgradeV2 = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <div className={classes.contentWrapper}>
                    <Typography variant="h5" color={'primary'}>
                        Gain 8+ extra clients per year (avg.) with a public profile fuelled by feedback you've already
                        collected
                    </Typography>
                    <div className={classes.ctaButton}>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="tertiary"
                            href={`/payment`}
                            onClick={() => track('clicked_go_public_private_mode_dashboard', { category: 'Dashboard' })}
                        >
                            Go public
                        </Button>
                    </div>
                    <Box marginBottom={'2rem'} marginTop={'2rem'}>
                        {PUBLIC_PROFILE_BENEFITS_V2.map(benefit => {
                            return (
                                <PrivateDashboardListItemV2
                                    primary={benefit.primary}
                                    icon={benefit.icon}
                                    key={benefit.key}
                                />
                            );
                        })}
                    </Box>
                    {
                        hasApplicationFeature(GUIDE_TRACKER_FEATURE_SWITCH_OPTION) &&
                        <div>
                            <GuideTrackerForPrivateAdviserV2 />
                        </div>
                    }
                </div>
            </Grid>
        </div>
    );
};

export default PublicProfileUpgradeV2;
