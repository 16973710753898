import React from 'react';
import PropTypes from 'prop-types';
import { SimpleForm } from 'react-admin';
import LoginMessage from './LoginMessage';
import FormToolbar from '../Components/Toolbars/FormToolbar';
import {
    LoginEmailField,
    LoginPasswordField,
} from '../Components/Inputs/LoginFields';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from "@material-ui/core/Typography";
import {CONTACT} from "../constants";

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.number,
};

const styles = theme => ({
    card: {
        width: 464,
        padding: 0,
        background: '#a5dee2',
        borderRadius: `${theme.spacing(1.5)}px`,

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    cardContent: {
        '& .MuiTypography-root': {
            color: theme.palette.primary.main
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.primary.main
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
    },
    title: {
        textAlign: 'center',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    form: {
        '& > div': {
            padding: 0,
        },

        '& > div:last-child': {
            display: 'none',
        },

        '& > .MuiToolbar-root': {
            position: 'relative',
            padding: 0,

            '& > .MuiButton-contained': {
                margin: `0 auto`,
                backgroundColor: theme.palette.tertiary.main,

                '&.Mui-disabled': {
                    opacity: 0.85,
                    color: 'white',
                },
            },
        },
    },
    formInput: {
        margin: `10px 0`,
        width: '100%',
        maxWidth: 'unset',

        '& > .MuiInputLabel-outlined': {
            color: theme.palette.primary.main,

            '&.MuiInputLabel-shrink': {
                color: theme.palette.primary.main,
                transform: 'translate(14px, -14px) scale(0.75)',

                '&.Mui-error': {
                    color: theme.palette.error.main,
                },
            },
        },

        '& > .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.primary.contrastText,
        },
        '& .MuiOutlinedInput-notchedOutline ': {
            borderColor: 'transparent'
        }
    },
    formToolbar: {
        marginTop: 0,
        '& .MuiButton-contained' : {
            width: '100%',
        }
    },
    header: {
        textAlign: 'center',
    },

});

const LoginForm = props => {
    const { classes, loading, handleSubmit } = props;

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Typography variant="body1">
                    <h3 className={classes.header}><b>Log in to your VouchedFor account</b></h3>
                </Typography>
                <SimpleForm
                    className={classes.form}
                    save={handleSubmit}
                    saving={loading}
                    toolbar={<FormToolbar label="Log In" className={classes.formToolbar} />}
                >
                    <LoginEmailField className={classes.formInput} />
                    <LoginPasswordField className={classes.formInput} />
                </SimpleForm>
                <LoginMessage />
            </CardContent>
        </Card>
    );
};

LoginForm.propTypes = propTypes;

export default withStyles(styles)(LoginForm);
