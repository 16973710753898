import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import {
    TextInput,
    CheckboxGroupInput,
    RadioButtonGroupInput,
    SelectInput,
    required,
} from 'react-admin';
import { ExternalLink } from '../Links';
import { validateEmail, validateRequiredCheckboxInputGroup } from '../../validation';
import {
    REGISTER_VERTICAL_CHOICES,
    REGISTER_TITLE_CHOICES,
    TERMS_AND_CONDITIONS,
    REGISTER_FA_CHOICES,
    REGISTER_MA_CHOICES, GENDER_CHOICES,
} from '../../constants';

import withStyles from '@material-ui/core/styles/withStyles';

const getStyle = props => {
    if (_.get(props, 'hidden')) {
        return { display: 'none' };
    }

    return _.get(props, 'style');
};

const registerInputStyles = theme => ({
    termsCheckbox: {
        '& > legend': {
            display: 'none',
        },
    },
});

export const RegisterTitleField = props => {
    return (
        <SelectInput
            {...props}
            source="title"
            validate={required()}
            label="Title"
            choices={REGISTER_TITLE_CHOICES}
            style={getStyle(props)}
        />
    );
};

export const RegisterGenderField = props => {
    return (
        <SelectInput
            {...props}
            source="gender"
            validate={required()}
            label="Gender"
            choices={GENDER_CHOICES}
            style={getStyle(props)}
        />
    );
};

export const RegisterFirstNameField = props => {
    return (
        <TextInput
            {...props}
            source="first_name"
            validate={required()}
            label="First name"
            style={getStyle(props)}
        />
    );
};

export const RegisterLastNameField = props => {
    return (
        <TextInput
            {...props}
            source="last_name"
            validate={required()}
            label="Last name"
            style={getStyle(props)}
        />
    );
};

export const RegisterVerticalField = props => {
    return (
        <SelectInput
            {...props}
            source="vertical"
            validate={required()}
            label="Profession"
            choices={REGISTER_VERTICAL_CHOICES}
            style={getStyle(props)}
        />
    );
};

export const RegisterFinancialAdviceOffered = props => {
    return (
        <RadioButtonGroupInput
            {...props}
            key="financial_advice"
            source="financial_advice"
            validate={required()}
            label="Select financial advice offered:"
            choices={_.get(props, 'choices', REGISTER_FA_CHOICES)}
            style={getStyle(props)}
        />
    );
};

export const RegisterMortgageAdviceOffered = props => {
    return (
        <RadioButtonGroupInput
            {...props}
            key="mortgage_advice"
            source="mortgage_advice"
            validate={required()}
            label="Select mortgage advice offered:"
            choices={_.get(props, 'choices', REGISTER_MA_CHOICES)}
            style={getStyle(props)}
        />
    );
};

export const RegisterEmailField = props => {
    return (
        <TextInput
            {...props}
            source="email"
            validate={validateEmail}
            type="email"
            label="Email"
            style={getStyle(props)}
            fullWidth
        />
    );
};

export const RegisterTermsCheckbox = withStyles(registerInputStyles)(
    ({ classes, className, ...props }) => {
        return (
            <CheckboxGroupInput
                {...props}
                className={cn(classes.termsCheckbox, className)}
                source="confirm"
                label=""
                validate={[required(), validateRequiredCheckboxInputGroup]}
                style={getStyle(props)}
                choices={[
                    {
                        id: 'agree',
                        name: (
                            <span>
                                I agree to the{' '}
                                <ExternalLink href={TERMS_AND_CONDITIONS}>
                                    Terms &amp; Conditions
                                </ExternalLink>
                            </span>
                        ),
                    },
                ]}
            />
        );
    }
);
