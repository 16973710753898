import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PageTabWithTooltip from './PageTabWithTooltip';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(5),
        '& .MuiTab-wrapper': {
            flexDirection: 'row',
            '& .needs-details': {
                marginBottom: 0,
                padding: '0px 9px !important',

            }
        }
    },
    disabled: {
        opacity: 0.4,
        cursor: 'default',
    },
});

class PageTabs extends Component {
    state = {
        activeTab: false,
        nextRouteProps: null,
    };

    componentDidMount() {
        this.setActiveTab();
    }

    setActiveTab = () => {
        const { activeTab } = this.state;
        const { routes } = this.props;

        if (!activeTab) {
            const currentRoute = routes.filter(route => route.link === window.location.pathname)[0];

            if (currentRoute) {
                this.setState({ activeTab: currentRoute.label });
            }
        }
    };

    onTabClick = tab => () => {
        const { link, label, parentSidebarItem, onTabClick } = tab;
        const url = window.location.href;

        if (onTabClick) {
            return onTabClick();
        }

        if (url.includes(link)) {
            window.location.reload();
        }

        this.setState(
            {
                activeTab: label,
                nextRouteProps: {
                    pathname: link,
                    state: {
                        parentSidebarItem: !link || url.includes(link) ? null : parentSidebarItem,
                        activeTab: label,
                    },
                },
            },
            () => this.setState({ nextRouteProps: null })
        );
    };

    renderTabs = tab => {
        const { width, classes } = this.props;
        const props = {
            className: tab.disabled ? classes.disabled : null,
            value: tab.label,
            key: tab.label,
            label: tab.label,
            icon: tab.icon,
            onClick: tab.disabled ? () => {} : this.onTabClick(tab),
        };

        if (isWidthDown('sm', width) && tab.disabled) {
            return null;
        }

        if (tab.tooltipTitle) {
            return <PageTabWithTooltip title={tab.tooltipTitle} {...props} />;
        }

        return <Tab {...props} />;
    };

    render() {
        const { nextRouteProps } = this.state;
        const { routes, classes } = this.props;

        if (nextRouteProps) {
            return <Redirect to={nextRouteProps} />;
        }

        return (
            <Tabs
                className={classes.root}
                value={this.state.activeTab}
                indicatorColor="primary"
                scrollButtons="auto"
                variant="scrollable"
            >
                {routes.map(tab => this.renderTabs(tab))}
            </Tabs>
        );
    }
}

export default withStyles(styles)(withWidth()(PageTabs));
