import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Datagrid,
    DateField,
    Filter,
    TextField,
    TextInput,
    NumberField,
    List,
    BooleanField,
    SelectInput,
    ReferenceInput,
    useListContext,
    Responsive,
    Toolbar, toggleListItemExpand,
} from 'react-admin';
import ActionManyButton from './ActionManyButton';
import DateRangeCustom from '../../Dashboard/Elevation/Components/DateRange';
import { formatRange } from '../../utils';
import {
    Button as MuiButton,
    DialogContent,
    DialogActions,
    Dialog,
    Select,
    Typography,
    MenuItem,
} from '@material-ui/core';
import { ExternalLink } from '../../Components/Links';
import ContentTooltip from '../../Components/ConsumerReactComponents/ContentTooltip';
import {useDispatch, useSelector} from "react-redux";
import * as analytics from '../../analytics';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiToolbar-root': {
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 0,
            '& form': {
                alignItems: 'center',
            },
            zIndex: 0,
        },
        '& .MuiPaper-root > .MuiToolbar-root': {
            padding: '0 16px',
            justifyContent: 'flex-end',
            gap: 20,
            flexDirection: 'row-reverse',
            '& .MuiTypography-root': {
                marginTop: 0,
                marginBottom: 0,
            },
        },
        '& .MuiPaper-rounded': {
            padding: 0,
        },
    },
    filters: {
        alignSelf: 'center',
        marginTop: 0,
        minHeight: '100%',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiButton-root': {
            backgroundColor: theme.palette.tertiary.main,
            color: '#fff',
            paddingLeft: 8,
            paddingRight: 16,
            minHeight: 40,
            minWidth: 125,
        },
        '& .filter-field': {
            alignItems: 'center',
        },
    },
    breakWord: {
        wordBreak: 'break-all',
    },
    table: {
        '& .MuiTableCell-root': {
            padding: '4px',
        },
    },
    readReviewButton: {
        color: theme.palette.secondary.main,
    },
    button: {
        padding: '2px 16px !important',
        width: '100%',
        maxWidth: '250px',
        minHeight: 40,
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
    paginationToolbar: {
        justifyContent: 'flex-end !important',
        flexDirection: 'row !important',
        paddingBottom: '16px !important',
        gap: 16,
    },
    paginationSelect: {
        maxWidth: 100,
    },
    redField: {
        color: theme.palette.error.main,
    },
    greenField: {
        color: theme.palette.secondary.dark,
    },
    noData: {
        padding: 16,
    },
    withTooltip: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& .MuiSvgIcon-root': {
            left: 0,
            bottom: 0,
        },
    },
    nameSelect: {
        minWidth: 170,
    },
    selectWithNoDefault: {
        '& .MuiMenu-paper': {
            '& .MuiMenu-list': {
                '& li:first-child': {
                    display: 'none',
                },
            },
        },
    },
}));

export const getSurveyUrlByTypeAndId = (surveyType, id) => {
    return (surveyType === 'review' ? 'reviews/' : 'first-impressions/') + id;
};

const ReadReviewButton = ({ record, style }) => {
    if (record) {
        const { survey } = record;

        return (
            <Button
                label={`Read ${survey.type.replace('_', ' ')}`}
                href={getSurveyUrlByTypeAndId(survey.type, survey.id)}
                className={style}
                onClick={() => {analytics.track('Action List Read Review button press', {reviewId: survey.id})}}
            />
        );
    }
};

const ActionFilters = props => {
    const { displayedFilters, selectedIds } = useListContext();
    const [isDateSet, setIsDateSet] = useState(false);
    const classes = useStyles();
    const { range, setRange, setShowDialog } = props;
    const [selectedActionIds, setSelectedActionIds] = useState(new Set());
    const [filtersAdded, setFiltersAdded] = useState(new Set())

    useEffect(() => {
        if (!displayedFilters?.date && isDateSet) {
            setRange({ from: null, to: null });
            setIsDateSet(false);
        } else {
            setIsDateSet(true);
        }

        if (Object.entries(displayedFilters).length > 0) {
            const filtersSet = new Set(Object.keys(displayedFilters));
            const diff = filtersSet.difference(filtersAdded);
            const [first] = diff;

            if (first) {
                setFiltersAdded(prev => new Set(prev).add(first));
                analytics.track('Action List Add Filter button press', { filter: first });
            }
        }
    }, [displayedFilters]);

    useEffect(() => {
        const idsSet = new Set(selectedIds);
        if (idsSet.size) {
            const idSet = idsSet.difference(selectedActionIds);
            const [first] = idSet;

            if (first) {
                setSelectedActionIds(prev => new Set(prev).add(first));
                analytics.track('Action List item select button press', {actionId: first})
            }
        }
    }, [selectedIds])
    
    return (
        <Filter className={classes.filters} {...props}>
            <SelectInput
                variant="outlined"
                label="Actioned"
                source="actioned"
                inputProps={{
                    MenuProps: {
                        className: classes.selectWithNoDefault,
                    },
                }}
                emptyValue={false}
                defaultValue={false}
                alwaysOn
                choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
            />
            <SelectInput
                variant="outlined"
                label="Viewed"
                source="viewed"
                inputProps={{
                    MenuProps: {
                        className: classes.selectWithNoDefault,
                    },
                }}                
                emptyValue={false}
                choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
            />
            <TextInput variant="outlined" label="Client name" source="client_name" />
            <TextInput variant="outlined" label="Client email" source="client_email" />
            <SelectInput
                variant="outlined"
                label="Category"
                source="category"
                inputProps={{
                    MenuProps: {
                        className: classes.selectWithNoDefault,
                    },
                }}
                choices={[
                    { id: 'opportunity', name: 'Opportunity' },
                    { id: 'potential_risk', name: 'Potential risk' },
                ]}
            />
            <ReferenceInput
                label="Description"
                variant="outlined"
                source="name"
                className={classes.nameSelect}
                reference="action-list/name-choices"
                resource="action-list">
                <SelectInput variant="outlined" />
            </ReferenceInput>
            <MuiButton
                source="date"
                className={classes.button}
                variant="outlined"
                onClick={() => setShowDialog(true)}>
                {range.to && range.from
                    ? formatRange(range).from + ' - ' + formatRange(range).to
                    : 'Filter by date'}
            </MuiButton>
        </Filter>
    );
};

const ExpandedRow = ({ record, isSmall , id}) => {
    const [expandedIds, setExpandedIds] = useState(new Set());

    if (!expandedIds.has(id)){
        setExpandedIds(prev => new Set(prev).add(id))
        analytics.track('Action List item expanded', {actionId: id})
    }

    const classes = useStyles();

    return (
        <>
            <Typography variant={'body2'}>
                {record.client_name} ({record.client_email})
            </Typography>
            <br />
            <Typography className={classes.greenField} variant={'body2'}>
                {record.name}
            </Typography>
            <Typography variant="body2">
                <ExternalLink href={record.external_link}>{record.action}</ExternalLink>
            </Typography>

            {record.category === 'Opportunity' ? (
                <>
                    <br />
                    <Typography className={classes.withTooltip} variant={'body2'}>
                        Potential Value: &pound;{record.potential_value}{' '}
                        <ContentTooltip
                            interactive
                            placement="top"
                            title={
                                <>
                                    <a
                                        href="https://support.vouchedfor.co.uk/article/3gz2ndixt6-estimated-values-of-commercial-opportunities"
                                        target="_blank">
                                        Read more
                                    </a>{' '}
                                    on how we calculated this potential value
                                </>
                            }
                        />
                    </Typography>
                </>
            ) : null}

            {isSmall ? (
                <>
                    <br />
                    <Typography variant={'body2'}>
                        Actioned: {record.actioned ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant={'body2'}>
                        Viewed: {record.viewed ? 'Yes' : 'No'}
                    </Typography>
                    <MuiButton
                        color="secondary"
                        variant="outlined"
                        href={getSurveyUrlByTypeAndId(record.survey.type, record.survey.id)}>
                        {`Read ${record.survey.type}`}
                    </MuiButton>
                </>
            ) : null}
        </>
    );
};

const ActionPagination = () => {
    const { perPage, setPerPage, total } = useListContext();
    const classes = useStyles();

    return total > 0 ? (
        <Toolbar className={classes.paginationToolbar}>
            <Typography variant={'h5'}>Show rows per page</Typography>

            <Select
                className={classes.paginationSelect}
                variant="outlined"
                value={perPage}
                onChange={e => setPerPage(e.target.value)}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={0}>All</MenuItem>
            </Select>
        </Toolbar>
    ) : null;
};

const CustomXsDate = ({ record }) => {
    return (
        <>
            <div>
                <DateField record={record} label={'Date'} source="submitted_at" />
            </div>
            <div>
                <TextField record={record} label="Client" source="client_name" />
            </div>
        </>
    );
};

const CustomXsCategory = ({ record }) => {
    return (
        <>
            <div>
                <CustomCategoryField record={record} label="Category" source="category" />
            </div>
            <div>
                <TextField record={record} label="Description" source="name" />
            </div>
        </>
    );
};

const CustomBooleanField = props => {
    const classes = useStyles();
    return (
        <BooleanField
            className={`${props.record[props.source] ? classes.greenField : classes.redField}`}
            {...props}
        />
    );
};

const CustomCategoryField = props => {
    const classes = useStyles();
    return (
        <TextField
            className={`${
                props.record.category === 'Opportunity' ? classes.greenField : classes.redField
            }`}
            {...props}
        />
    );
};

const CustomPotentialValueField = props => {
    if (props.record.category === 'Potential Risk' || props.record.potential_value === 0) return null;
    return <NumberField {...props} />;
};

const CustomMediumDatagrid = props => {
    const classes = useStyles();

    const { filterValues, ids } = useListContext();
    const dispatch = useDispatch();

    const expanded = useSelector(state => state.admin.resources['action-list'].list.expanded);

    useEffect(() => {
        expanded.forEach(id =>
            dispatch(toggleListItemExpand('action-list', id))
        );
    }, [filterValues, ids]);

    if (props.total === 0) {
        return (
            <div className={classes.noData}>
                <Typography variant={'h5'}>No records found for your current selection</Typography>
            </div>
        );
    }
    return (
        <Datagrid {...props} className={classes.table} expand={<ExpandedRow />} rowClick="expand">
            <DateField label={'Date'} source="submitted_at" />
            <CustomBooleanField
                valueLabelTrue="Yes"
                valueLabelFalse="No"
                label={'Actioned'}
                source="actioned"
            />
            <CustomBooleanField
                valueLabelTrue="Yes"
                valueLabelFalse="No"
                label={'Viewed'}
                source="viewed"
            />
            <TextField label="Client" source="client_name" />
            <CustomCategoryField label="Category" source="category" />
            <TextField label="Description" source="name" />
            <CustomPotentialValueField
                headerClassName="custom-potential-value-header"
                label="Potential Value"
                source="potential_value"
                options={{
                    style: 'currency',
                    currency: 'GBP',
                    minimumFractionDigits: 0,
                }}
            />
            <ReadReviewButton style={classes.readReviewButton} />
        </Datagrid>
    );
};

const CustomSmallDatagrid = props => {
    const classes = useStyles();

    // Lines 432 to 441 (same with the above CustomMediumDatagrid) are needed because
    // at this point the react admin version we have does not include the useExpandAll hook
    // that would allow accessing a global state which tells you if at least one element is expanded.
    // https://github.com/marmelab/react-admin/issues/6167

    // In order to close expanded panels on list change we could use useExpanded hook.
    // Problem is that hook works for 1 identifier and does not accept a list of ids to close.
    // We can't use a hook inside loops and there s no property exposed on the DataGrid that would control the expanded state.

    // Given all the above, we need to get the redux state and dispatch the actions in the useExpanded hook in a loop,
    // hence the custom code below.

    // When moving to react admin 4 we need to get rid of this and use useExpandAll hook
    // as react-admin 4 no longer relies on Redux. Instead, it relies on React context and third-party libraries (e.g. react-query).

    const { filterValues, ids } = useListContext();
    const dispatch = useDispatch();

    const expanded = useSelector(state => state.admin.resources['action-list'].list.expanded);

    useEffect(() => {
        expanded.forEach(id =>
            dispatch(toggleListItemExpand('action-list', id))
        );
    }, [filterValues, ids]);

    if (props.total === 0) {
        return (
            <div className={classes.noData}>
                <Typography variant={'h5'}>No records found for your current selection</Typography>
            </div>
        );
    }
    return (
        <Datagrid className={classes.table} expand={<ExpandedRow isSmall />} rowClick="expand">
            <CustomXsDate label="Date" />
            <CustomXsCategory label="Category" />
        </Datagrid>
    );
};

const ActionTable = props => {
    const classes = useStyles();
    const [range, setRange] = useState({ from: null, to: null });
    const [showDialog, setShowDialog] = useState(false);

    const handleCloseClick = () => setShowDialog(false);
    const handleClearClick = () => {
        setRange({ to: null, from: null });
        setShowDialog(false);
    };

    return (
        <>
            <Dialog className={classes.dialog} open={showDialog} onBackdropClick={handleCloseClick}>
                <DialogContent>
                    <DateRangeCustom range={range} setRange={setRange} />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <MuiButton label="ra.action.cancel" onClick={handleClearClick}>
                        Clear interval
                    </MuiButton>
                    <MuiButton
                        color="secondary"
                        label="ra.action.cancel"
                        onClick={handleCloseClick}>
                        OK
                    </MuiButton>
                </DialogActions>
            </Dialog>
            <List
                {...props}
                className={classes.root}
                exporter={false}
                bulkActionButtons={<ActionManyButton />}
                pagination={<ActionPagination />}
                empty={false}
                hasCreate={false}
                perPage={10}
                filters={
                    <ActionFilters
                        range={range}
                        setRange={setRange}
                        setShowDialog={setShowDialog}
                    />
                }
                filter={{ ...(range.to && range.from && { ...formatRange(range) }) }}
                sort={{ field: 'submitted_at', order: 'DESC' }}
                filterDefaultValues={{ actioned: false }}>
                <Responsive small={<CustomSmallDatagrid />} medium={<CustomMediumDatagrid />} />
            </List>
        </>
    );
};

export default ActionTable;
