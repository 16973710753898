import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '../../Components/Buttons/CustomButton';
import { LOCAL_STORAGE } from '../../constants';
import { useDataProvider } from 'react-admin';
import { formatRange } from '../../utils';
import { SERVICE_TYPE_FILTER_LEGAL, SERVICE_TYPE_FILTERS } from '../../constants/elevation';
import { getServiceTypeAsString, isLegalAdviser } from '../../Helpers/elevation';
import Spinner from '../../Components/Spinner';
import CustomCard from '../DashboardV3/Components/CustomCard';
import Grid from '@material-ui/core/Grid';
import { track } from '../../analytics';

const useStylesCustom = makeStyles(theme => ({
    cardsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 260,
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            justifyContent: 'space-around',
            marginBottom: theme.spacing(2),
        },
    },
    button: {
        width: '180px',
    },
    pageHeading: {
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
        justifyContent: 'left',
        alignItems: 'center',
        '& .MuiTypography-h4': {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            textAlign: 'center',
            justifyContent: 'space-around',
        },
    },
    buttonToElevation: {
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
        justifyContent: 'left',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            textAlign: 'center',
            justifyContent: 'space-around',
        },
    },
    contentWrapper: {
        maxWidth: '1100px',
        alignSelf: 'center',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            padding: `0 0 0 ${theme.spacing(2)}px`,
        },
    },
    container: {
        justifyContent: 'center',
    },
}));

const mainCardDetails = {
    prospect_intentions: {
        title: 'Prospect Conversion',
        subtitle: 'of prospects intend to become a client',
        goal: 1,
    },
    client_advocacy: {
        title: 'Client Advocacy',
        subtitle: 'of clients are passionate advocates',
        goal: 2,
    },
    safeguarding_clients: {
        title: 'Mitigating Risk',
        subtitle: 'of reviews don’t exceed our risk threshold',
        goal: 3,
    },
};

const PrivateDashboardStatsV2 = () => {
    const dataProvider = useDataProvider();
    const classes = useStylesCustom();

    const [stats, setStats] = useState(null);
    const [isStatsFetched, setIsStatsFetched] = useState(false);
    const [range, setRange] = useState({ from: null, to: null });
    const basicInfoString = JSON.parse(localStorage.getItem(LOCAL_STORAGE.BASIC_INFO));
    const vertical = basicInfoString.data?.permissions?.vertical
    const [serviceType, setServiceType] = useState(isLegalAdviser(vertical)
        ? SERVICE_TYPE_FILTER_LEGAL
        : null);

    const fetchStatistics = () => {
        let params = formatRange(range);
        if (!serviceType) {
            dataProvider('GET_LIST', 'elevation/dashboard/highest-statistics', {filter: {...params}}).then(res => {
                const type = SERVICE_TYPE_FILTERS.find(x => x.type === res.data['serviceType']);
                setServiceType(type);
                params['service_type'] = getServiceTypeAsString(type);

                dataProvider('GET_LIST', 'elevation/dashboard/elevation-statistics', {filter: {...params}})
                    .then(res => {
                        setStats(res.data)
                        setIsStatsFetched(true)
                    }).catch((e) => {
                    setIsStatsFetched(true);
                })
            });
        } else {
            params['service_type'] = getServiceTypeAsString(serviceType);
            dataProvider('GET_LIST', 'elevation/dashboard/elevation-statistics', {filter: {...params}})
                .then(res => {
                    setStats(res.data)
                    setIsStatsFetched(true)
                }).catch(() => setIsStatsFetched(true));
        }
    }

    useEffect(()=>{
        fetchStatistics();
    },[])

    return (
        <Grid container className={classes.container}>
            <div className={classes.contentWrapper}>
                <div className={classes.pageHeading}>
                    <Typography variant="h4" color={'primary'}>Explore your Elevation feedback</Typography>
                </div>
                {!isStatsFetched && <Spinner />}
                {isStatsFetched && stats &&
                    <>
                        <div className={classes.cardsWrapper}>
                            {Object.keys(mainCardDetails).map((key) => {
                                return (
                                    <CustomCard
                                        title={mainCardDetails[key].title}
                                        subtitle={mainCardDetails[key].subtitle}
                                        subheading={stats[key].professionalReviewCount < 5 && 'You need at least 5 reviews for this goal to see data'}
                                        value={stats[key].professionalReviewCount >= 5 ? (stats[key].professionalAvg ? `${Math.round(stats[key].professionalAvg)}%` : '0%') : '-'}
                                        main={true}
                                        mainNonClickable={true}
                                        active={true}
                                    />
                                );
                            })}
                        </div>
                    </>
                }
                <div className={classes.buttonToElevation}>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="tertiary"
                        href={`/elevation-dashboard`}
                        onClick={() => track('clicked_see_all_data_private_mode_dashboard', { category: 'Dashboard' })}
                    >
                        See all data
                    </Button>
                </div>
            </div>
        </Grid>
    );
};

export default PrivateDashboardStatsV2;
