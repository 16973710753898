import React from 'react'
import { VERTICAL } from '../constants';
import { NeedsDetail } from '../AppLayout/Sidebar/SidebarListItem';

const { FINANCIAL_ADVISER, LEGAL_ADVISER, ACCOUNTANT } = VERTICAL;

export function getProfessionalTabs(vertical, id, aboutNeedsDetails) {
    const tabs = {
        about: {
            label: 'About me',
            link: `/professional/about/${id}`,
            icon: aboutNeedsDetails ? <NeedsDetail /> : null,
        },
        qualification: {
            label: 'Qualifications',
            link: `/professional/qualifications/${id}`,
        },
        experience: {
            label: 'Experience',
            link: `/professional/experience/${id}`,
        },
        services: {
            label: 'Services',
            link: `/services-list/${id}`,
        },
        location: {
            label: 'Location',
            link: `/professional/location/${id}`,
        },
        disclaimer: {
            label: 'Disclaimer',
            link: `/professional/disclaimer/${id}`,
        },
    };

    if (vertical === FINANCIAL_ADVISER) {
        return [
            tabs.about,
            tabs.qualification,
            tabs.experience,
            tabs.services,
            tabs.location,
            tabs.disclaimer,
        ];
    }

    if (vertical === LEGAL_ADVISER) {
        return [
            tabs.about,
            tabs.qualification,
            tabs.experience,
            tabs.services,
            tabs.location,
            tabs.disclaimer,
        ];
    }

    if (vertical === ACCOUNTANT) {
        return [
            tabs.about,
            tabs.qualification,
            tabs.experience,
            tabs.services,
            tabs.location,
        ];
    }

    return [];
}

export function getFeesTabs(showFeeInsightsTab) {
    const feeTab = {
        label: 'Your Fee Data',
        link: '/fees',
        onTabClick: () => {
            window.location = '/fees';
        },
    };

    const insightsTab = {
        label: 'Insights',
        link: '/fees-insights',
    };

    return showFeeInsightsTab ? [insightsTab, feeTab] : [feeTab];
}
