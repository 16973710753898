import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import HighlightSocialShare from './HighlightSocialShare';
import { FormSectionTitle } from '../../Components/Typography/Headlines';
import RenderQuestions from './RenderQuestions';
import { usePermissions, useDataProvider } from 'react-admin';

import Typography from '@material-ui/core/Typography';
import { MULTIPLE_SHARE_SELECTION_ID } from '../../utils';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import DescriptionIcon from '@material-ui/icons/DescriptionRounded';
import Grid from "@material-ui/core/Grid";
import RisksAndOpportunities from './RisksAndOpportunities'
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
    },
    gridLayout: {
        display: 'flex',
        gap: '4rem',
        [theme.breakpoints.down('sm')]: {
            gap: '1rem',
            flexDirection: 'column'
        },
    },
    ratingsLayout: {
        display: 'flex',
        gap: '1.5rem',
    },
    overAllColor: {
        color: theme.palette.primary.main,
    },
    descriptionIcon: {
        marginRight: '15px',
        verticalAlign: 'top'
    },
    responsesList: {
        padding: '8px 0px',
        maxWidth: 900,
        width: '100%',
        '& .MuiTypography-h6': {
            paddingTop: 0,
            lineHeight: 1
        },
    }
}));

const initialiseData = (questions) => {
    const publicQuestions = questions.filter(
        (group) => Boolean(group.question.visible_in_review_listing) === true
    );

    const isPublicQuestion = (group) => publicQuestions.includes(group);

    const demographicQuestions = questions.filter(
        (group) => !isPublicQuestion(group) && group.question.demographic_question
    );

    const privateQuestions = questions.filter(
        (group) => !isPublicQuestion(group) && !group.question.demographic_question
    );

    return {
        publicQuestions,
        privateQuestions,
        demographicQuestions,
    };
};

const StyledRating = withStyles(theme => ({
    iconFilled: {
        color: theme.palette.secondary.main,
        marginTop: '5px',
    },
    iconEmpty: {
        marginTop: '5px',
    },
}))(Rating);

export const ReviewRating = ({ value }) => {
    return (
        <StyledRating
            name={'read-only'}
            value={parseFloat(value)}
            precision={0.1}
            size={'large'}
            readOnly
        />
    );
};

export const ReviewRatings = (
    {
        averageScore,
        qualityOfAdvice,
        qualityOfService,
        valueForMoney,
    }) => {
    const classes = useStyles();

    return (
        <div>
            <Box className={classes.ratingsLayout}>
                <ReviewRating value={averageScore}/>
                <Typography className={classes.overAllColor} variant={'body1'}><b>Overall</b></Typography>
            </Box>
            <Box className={classes.ratingsLayout}>
                <ReviewRating value={qualityOfAdvice}/>
                <Typography variant={'body1'}>Advice</Typography>
            </Box>
            <Box className={classes.ratingsLayout}>
                <ReviewRating value={qualityOfService}/>
                <Typography variant={'body1'}>Service</Typography>
            </Box>
            <Box className={classes.ratingsLayout}>
                <ReviewRating value={valueForMoney}/>
                <Typography variant={'body1'}>Value</Typography>
            </Box>
        </div>
    );
};

const ReviewQuestions = props => {
    const [state, setState] = useState({
        publicQuestions: [],
        privateQuestions: [],
        demographicQuestions: [],
    });
    const elevationPermission = useSelector(({ basicInfo }) => basicInfo?.permissions?.elevation);
    const classes = useStyles();

    let questions = _.get(props, ['record', 'questions']);
    const reviewScores = _.get(props, ['record', 'review_scores']);
    const averageScore = _.get(props, ['record', 'average_score']);
    const isTransferredClientReview = _.get(props.record, 'is_transferred_client_review', false);

    const { onStarterPlan, publicQuestions, privateQuestions } = props;
    const { permissions } = usePermissions();
    const onPrivateMode = _.get(permissions, 'elevation_private_mode');
    const isFirmOnElevation = permissions?.is_firm_on_elevation;
    const dataProvider = useDataProvider()

    useEffect(() => {
        if (elevationPermission) {
            dataProvider.update(`reviews/seen`, { id: props.record.id});
        }
    }, [])

    useEffect(() => {
        if (questions) {
            const data = initialiseData(questions);
            setState({ ...data });
        }
    }, [questions]);

    const qualityOfAdvice = _.get(reviewScores, 'financial_adviser_quality_of_advice');
    const qualityOfService = _.get(reviewScores, 'financial_adviser_quality_of_service');
    const valueForMoney = _.get(reviewScores, 'financial_adviser_value_for_money');
    if (_.isEmpty(questions)) return <div/>;

    return (
        <>
            <div id={onPrivateMode ? null : MULTIPLE_SHARE_SELECTION_ID}>
                <Box className={classes.gridLayout}>
                    {!isTransferredClientReview && publicQuestions &&
                        <ReviewRatings
                            averageScore={averageScore}
                            qualityOfAdvice={qualityOfAdvice}
                            qualityOfService={qualityOfService}
                            valueForMoney={valueForMoney}
                            record={props.record}
                        />
                    }
                    <div>
                        {!_.isEmpty(state.publicQuestions) && publicQuestions &&
                            state.publicQuestions.map(group => (
                                <HighlightSocialShare disable={onStarterPlan} key={group.label_template}>
                                    <RenderQuestions item={group} publicQuestions={publicQuestions} />
                                </HighlightSocialShare>
                            ))}
                    </div>
                </Box>

                <>
                    <RisksAndOpportunities
                        isOpportunity={false}
                        visible={elevationPermission && privateQuestions}
                        {...props?.record?.potential_risks}
                    />
                    <RisksAndOpportunities
                        isOpportunity={true}
                        visible={elevationPermission && privateQuestions}
                        {...props?.record?.opportunities}
                    />

                </>
                {(!_.isEmpty(state.privateQuestions) && privateQuestions) &&
                    (
                        <>
                            <FormSectionTitle title="All Private Responses" color={"textPrimary"} variant={"h4"} icon={<DescriptionIcon className={classes.descriptionIcon} />} />
                            {!isFirmOnElevation && (
                                <Typography variant="body1">
                                    To learn more, read our{' '}
                                    <a
                                        className={classes.link}
                                        target="_blank"
                                        href="https://support.vouchedfor.co.uk/article/434-new-client-experience-questions?preview=62e295fa86b3a97442471bd7">
                                        help article{' '}
                                    </a>
                                    on our 'New Client Experience Questions'
                                </Typography>
                            )}
                        </>
                    )}

                {
                    !_.isEmpty(state.privateQuestions) && privateQuestions &&
                    (
                        <div className={classes.responsesList}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {
                                        state.privateQuestions.map(group => (
                                            <RenderQuestions item={group} />
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    )
                }

                {
                    !_.isEmpty(state.demographicQuestions) && privateQuestions &&
                    (
                        <>
                            <FormSectionTitle title="Demographic questions" color={"textPrimary"} variant={"h4"} icon={<DescriptionIcon className={classes.descriptionIcon} />} />
                            <div className={classes.responsesList}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {
                                            state.demographicQuestions.map(group => (
                                                <RenderQuestions key={group.question.gka} item={group} />
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
};

export default ReviewQuestions;
