import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import OnboardingBannerBase from './OnboardingBannerBase';
import { Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import OnboardingBannerButton from './OnboardingBannerButton';
import * as analytics from '../../analytics';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ICONS } from '../../constants/emoji-constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    big: {
        fontSize: '24px',
    },
    bold: {
        fontWeight: 'bold',
    },
    titleFont: {
        fontSize: '18px',
    },
    green: {
        color: theme.palette.secondary.main,
    },
    lineSpacing: {
        lineHeight: '250%',
    },
    contentLineSpacing: {
        lineHeight: '200%',
    },
    tick: {
        height: '16px',
        width: 'auto',
        fill: theme.palette.secondary.main
    }
}));

const propTypes = {
    dashboardData: PropTypes.object,
};

const OnboardingBanner = ({ dashboardData }) => {
    const classes = useStyles();
    const theme = useTheme();

    const banner = useSelector(state => state.basicInfo.state);
    const [state, setState] = useState({
        title: '',
        content: '',
    });

    const reviewInviteCount = _.get(dashboardData, 'invited_clients_count', 0);
    const reviewCount = _.get(dashboardData, 'review_and_fi_count', 0);

    const onMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = () => {
        analytics.track(banner.analytics_action, {
            category: banner.analytics_category,
        });

        if (banner.is_external_redirect) {
            window.open(banner.redirect, '_blank');
        }
    };

    const formatHtml = useCallback(
        text => {
            if (text.match(/:[a-z/_]*:/g)) {
                const matches = text.match(/:[a-z/_]*:/g);
                for (let i = 0; i < matches.length; i++) {
                    text = text.replace(matches[i], ICONS[matches[i]]);
                }
            }

            if (text.includes('{reviewInviteCount}')) {
                const invite = reviewInviteCount <= 1 ? 'invite' : 'invites';
                const review = reviewCount <= 1 ? 'review' : 'reviews';
                text = text.replace('{reviewInviteCount}', reviewInviteCount);
                text = text.replace('{reviewCount}', reviewCount);
                text = text.replace('{invite}', invite);
                text = text.replace('{review}', review);
            }

            if (text.includes('class=')) {
                text = text.replace(/big/g, classes.big);
                text = text.replace(/green/g, classes.green);
                text = text.replace(/bold/g, classes.bold);
                text = text.replace(/titleFont/g, classes.titleFont);
                text = text.replace(/lineSpacing/g, classes.lineSpacing);
                text = text.replace(/contentLineSpacing/g, classes.contentLineSpacing);
                text = text.replace(/tick/g, classes.tick);
            }
            return text;
        },
        [classes, reviewInviteCount, reviewCount]
    );

    useEffect(() => {
        if (banner) {
            setState({
                title: formatHtml(banner.title),
                content: formatHtml(banner.content),
            });
        }
    }, [banner, formatHtml]);

    const bannerTitle = parse(state.title);
    return (
        banner != null && (
            <OnboardingBannerBase
                title={bannerTitle}
                content={
                    <div>
                        <Typography color="inherit">{parse(state.content)}</Typography>
                    </div>
                }
                buttons={
                    banner &&
                    banner.button_label &&
                    banner.redirect && (
                        <OnboardingBannerButton onClick={handleClick}>
                            {
                                banner.is_external_redirect
                                ? <span>{banner.button_label}</span>
                                : <Link to={banner.redirect}>{banner.button_label}</Link>
                            }
                        </OnboardingBannerButton>
                    )
                }
                note={banner.note}
                bannerImage={onMobile ? null : banner.banner_image}
            />
        )
    );
};

OnboardingBanner.propTypes = propTypes;

export default OnboardingBanner;
