import decode from 'jwt-decode';
import { LOCAL_STORAGE } from './constants';
import { MILLISECONDS_TO_SECONDS_DIVISER } from './constants/time';
const {
    LEXIKTOKEN,
    UNAUTHENTICATED_LEXIKTOKEN,
    UNAUTHENTICATED_PROFESSIONAL_ID,
} = LOCAL_STORAGE;

const IS_AUTHENTICATED_2FA_IN_PROGRESS = 'IS_AUTHENTICATED_2FA_IN_PROGRESS'

const tokenManager = {
    getLoggedInToken: function () {
        return localStorage.getItem(LEXIKTOKEN)
    },

    isTwoFactor: function () {
        const token = this.decodeToken(this.getLoggedInToken())

        if (!this.verifyToken(token)) {
            return false
        }

        return (token['roles'] ?? []).includes(IS_AUTHENTICATED_2FA_IN_PROGRESS)
    },

    decodeToken: function (token) {
        try {
            if (token.startsWith('{')) {
                return JSON.parse(token)
            }

            return decode(token)
        } catch (e) {
            return null
        }
    },

    verifyToken: function (token) {
        if (!token || typeof token !== 'object') {
            return false
        }

        if (token?.roles === undefined) {
            return false
        }

        const expiry = token?.exp || token?.expiry;

        return expiry >= Math.round((new Date().getTime()) / MILLISECONDS_TO_SECONDS_DIVISER)
    },

    setToken(token) {
        localStorage.setItem(LEXIKTOKEN, token)
        localStorage.removeItem(UNAUTHENTICATED_LEXIKTOKEN)
        localStorage.removeItem(UNAUTHENTICATED_PROFESSIONAL_ID)
    },
}

export default tokenManager
