import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import Button from '../../Components/Buttons/CustomButton'
import { FormSpy, useForm } from 'react-final-form';
import { FormDataConsumer } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PublishIcon from '@material-ui/icons/Publish';
import ReviewInviteFormContent from './ReviewInviteFormContent';
import StepsTitle from '../Content/StepsTitle'
import * as analytics from '../../analytics';

export const REVIEW_INVITE_TYPE_PROSPECT = 'first_impression';
export const REVIEW_INVITE_TYPE_CLIENT = 'review';
export const REVIEW_INVITE_TYPE_REVIEWS = 'testimonial';

const REVIEW_INVITE_TYPES = [
    REVIEW_INVITE_TYPE_PROSPECT,
    REVIEW_INVITE_TYPE_CLIENT,
    REVIEW_INVITE_TYPE_REVIEWS,
];

const BUTTONS = [
    {
        icon: <PersonAddIcon />,
        title: 'Invite Prospect',
        type: REVIEW_INVITE_TYPE_PROSPECT,
        description: 'This reviewer is not yet a client',
    },
    {
        icon: <PersonAddIcon />,
        title: 'Invite Client',
        type: REVIEW_INVITE_TYPE_CLIENT,
        description: 'This reviewer is a client of mine or has been a client in the last 5 years',
    },
    {
        icon: <PublishIcon />,
        title: 'Import Reviews',
        type: REVIEW_INVITE_TYPE_REVIEWS,
        description: 'Import reviews from other platforms',
    },
];

const useStyles = makeStyles(theme => ({
    content: {
        maxWidth: 1280,
    },
    buttonRow: {
        marginBottom: theme.spacing(2),
    },
    button: {
        textAlign: 'left',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: 'calc(25% - 32px)',
        minWidth: 250,

        '& .MuiSvgIcon-root': {
            fontSize: 40,
        },

        [theme.breakpoints.only('md')]: {
            width: 'calc(25% - 32px)',
        },

        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
    },
    buttonContent: {
        height: 110,
    },
    active: {
        '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText
        }
    }
}));

const useActiveType = () => {
    const form = useForm();
    const [activeType, setActiveTypeState] = useState();

    useEffect(() => {
        const parsedQuery = qs.parse(window.location.search);
        const typeFromQuery = _.get(parsedQuery, 't');

        if (Boolean(typeFromQuery) && REVIEW_INVITE_TYPES.includes(typeFromQuery)) {
            setActiveTypeState(typeFromQuery);
        }
    }, []);

    useEffect(() => {
        // Re-validate the form when switching the form type
        form.resumeValidation();
    }, [activeType, form]);

    const setActiveType = type => {
        form.pauseValidation();

        setActiveTypeState(type);

        const actionMap = {
            [REVIEW_INVITE_TYPE_PROSPECT]: 'Clicked invite prospect',
            [REVIEW_INVITE_TYPE_CLIENT]: 'Clicked invite client',
            [REVIEW_INVITE_TYPE_REVIEWS]: 'Clicked import',
        };

        analytics.track(actionMap[type], {
            category: 'InviteClient',
        });
    };

    return { activeType, setActiveType };
};

const ReviewInviteContent = () => {
    const classes = useStyles();
    const { activeType, setActiveType } = useActiveType();
    const [invalidForm, setInvalidForm] = useState(false);

    const handleButtonClick = type => e => {
        e.preventDefault();

        if (type !== activeType) {
            setActiveType(type);
        }
    };

    return (
        <div className={classes.content}>
            <FormSpy
                subscription={{ invalid: true }}
                onChange={({ invalid }) => {
                    setInvalidForm(invalid);
                }}
            />
            <StepsTitle title={'Step 1: What would you like to do?'} />
            <div className={classes.buttonRow}>
                {BUTTONS.map(button => (
                    <Button
                        key={button.type}
                        variant={button.type === activeType ? 'contained' : 'outlined'}
                        color={button.type === activeType ? 'tertiary' : 'default'}
                        onClick={handleButtonClick(button.type)}
                        startIcon={button.icon}
                        className={classes.button}
                    >
                        <div className={`${classes.buttonContent} ${button.type === activeType ? classes.active : ''}`}>
                            <Typography>
                                <b>{button.title}</b>
                            </Typography>
                            <Typography variant="caption">{button.description}</Typography>
                        </div>
                    </Button>
                ))}
            </div>
            <FormDataConsumer>
                {() => {
                    switch (activeType) {
                        case REVIEW_INVITE_TYPE_CLIENT:
                            return <ReviewInviteFormContent invalidForm={invalidForm} inviteType={'review'} activeType={activeType} />;
                        case REVIEW_INVITE_TYPE_REVIEWS:
                            return <ReviewInviteFormContent invalidForm={invalidForm} inviteType={'testimonial'} activeType={activeType} />;
                        case REVIEW_INVITE_TYPE_PROSPECT:
                            return <ReviewInviteFormContent invalidForm={invalidForm} inviteType={'first_impression'} activeType={activeType} />;
                        default:
                            return null
                    }
                }}
            </FormDataConsumer>
        </div>
    );
};

export default ReviewInviteContent;
