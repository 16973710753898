import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ErrorButton from '../Buttons/ErrorButton';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '../Buttons/CustomButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/es/DialogContentText';
import { makeStyles } from '@material-ui/core';

const propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    SaveButtonProps: PropTypes.object,
    ErrorButtonProps: PropTypes.object,
    submitting: PropTypes.bool,
    message: PropTypes.any,
    modalWidth: PropTypes.number,
};

const defaultProps = {
    open: false,
    onClose: () => {},
    children: null,
    title: '',
    message: null,
    submitting: false,
    modalWidth: null,
    SaveButtonProps: {
        label: '',
        onClick: values => {},
    },
    ErrorButtonProps: {
        label: '',
        onClick: () => {},
    },
};

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'relative',
        maxWidth: theme.spacing(80),
        outline: 0,

        [theme.breakpoints.only('xs')]: {
            overflowY: 'scroll',
            maxHeight: '80%',
        },
    },
    dialogTitle: {
        padding: `0 0 ${theme.spacing(1)}px 0`,

        '& > h2': {
            padding: 0,
            color: theme.palette.secondary.main,
        },
    },
    dialogContent: {
        padding: 0,

        '& > div': {
            margin: 0,
        },
    },
    dialogActions: {
        paddingTop: theme.spacing(2),
    },
    saveBtn: {
        marginLeft: theme.spacing(1),
    },
    closeBtn: {
        position: 'absolute',
        right: 8,
        top: 8,
        fontSize: theme.spacing(3),
        zIndex: 3,
    },
}));

const getPaperStyle = (modalWidth = null) => {
    if (!modalWidth) {
        return {};
    }

    return { width: modalWidth, maxWidth: 'initial' };
};

const DialogBase = props => {
    const {
        open,
        onClose,
        title,
        modalWidth,
        children,
        message,
        values,
        submitting,
        className,
        ErrorButtonProps,
        SaveButtonProps,
        hideClose,
    } = props;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const closeModal = () => {
        setIsOpen(false);

        onClose();
    };

    const handleSaveButtonClick = () => {
        SaveButtonProps.onClick(values);
    };

    return (
        <Dialog
            data-test={'dialog-wrapper'}
            disableBackdropClick={!!props.hideClose}
            disableEscapeKeyDown={!!props.hideClose}
            open={isOpen}
            onClose={closeModal}
            fullWidth
            PaperProps={{
                className: cn({ [classes.paper]: true, [className]: Boolean(className) }),
                style: getPaperStyle(modalWidth),
            }}>
            {hideClose ? null : (
                <IconButton
                    data-test={'dialog-close-icon'}
                    aria-label="close"
                    size="small"
                    onClick={closeModal}
                    className={classes.closeBtn}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            )}
            <DialogTitle data-test={'dialog-title'} className={classes.dialogTitle}>{title}</DialogTitle>
            <DialogContent data-test={'dialog-content'} className={classes.dialogContent}>
                <DialogContentText component="div">{message}</DialogContentText>
                {children}
            </DialogContent>
            <DialogActions data-test={'dialog-actions'} className={classes.dialogActions}>
                <div>
                    {ErrorButtonProps.label && (
                        <ErrorButton onClick={ErrorButtonProps.onClick} disabled={submitting}>
                            {ErrorButtonProps.label}
                        </ErrorButton>
                    )}
                    {SaveButtonProps.label && (
                        <Button
                            data-test={'dialog-save-button'}
                            variant="contained"
                            color="tertiary"
                            onClick={handleSaveButtonClick}
                            disabled={submitting}
                            className={classes.saveBtn}>
                            {SaveButtonProps.label}
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

DialogBase.propTypes = propTypes;
DialogBase.defaultProps = defaultProps;

export default DialogBase;
