import React from 'react';
import { NotFound as RANotFound } from 'react-admin';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import tokenManager from './tokenManager';

/**
 * This is a "custom" 404 page component, that is just basically a
 * check for if the user is using a 2FA JWT, then redirect them back
 * to the /2fa page.
 *
 * It returns the original 404 page component.
 */
export const NotFound = () => {
    const history = useHistory();

    useEffect(() => {
        if (tokenManager.isTwoFactor()) {
            history.push('/2fa');
        }
    }, []);

    return <RANotFound />
}