import React from 'react';
import { required } from 'react-admin';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import FormLongTextInput from '../../Components/Inputs/Form/FormLongTextInput';
import EmailSubject from '../../Components/Typography/EmailSubject';
import API_KEYS from '../../constants/api-keys';

const useStyles = makeStyles(() => ({
    label: {
        transform: 'translate(0, -3px) scale(0.75)',
    },
}));

const ReviewInviteEmailTemplate = ({ firstName, permalink, inputProps = {} }) => {
    const classes = useStyles();
    const adviserUrlWithEnter = `${API_KEYS.consumer_url}/${permalink?.url}/write-review\n`;
    const adviserUrlWithSpace = `${API_KEYS.consumer_url}/${permalink?.url}/write-review `;

    const validateTemplate = (value) => {

        if (value.includes(adviserUrlWithEnter) || value.includes(adviserUrlWithSpace)) {
            return null;
        }
        return `Adviser's url is wrong or missing, please use ${adviserUrlWithSpace}`;
    };

    return (
        <>
            <EmailSubject
                content={`${firstName} has requested that you leave them a review on VouchedFor`}
            />
            <EmailSubject
                content={
                    <>
                        <b>Hi &#123;first name&#125;</b> (this will be automatically personalised
                        for each client)
                    </>
                }
                subject={false}
            />
            <FormLongTextInput
                validate={[required(), validateTemplate]}
                label=""
                source="email_template"
                InputLabelProps={{
                    classes: {
                        shrink: classes.label,
                    },
                }}
                {...inputProps}
            />
        </>
    );
};

const mapStateToProps = ({ basicInfo }) => {
    return {
        firstName: basicInfo.first_name,
        permalink: basicInfo.permalink,
    };
};

export default connect(mapStateToProps)(ReviewInviteEmailTemplate);
