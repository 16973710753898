import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontFamily: "'Material Symbols Outlined'",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: theme.spacing(4),
        color: theme.palette.primary.main,
        display: 'inline-block',
        verticalAlign: 'middle',
        textRendering: 'optimizeLegibility',
        marginRight: theme.spacing(2),
    },
}));
const PrivateDashboardListItemV2 = ({ primary, icon }) => {
    const classes = useStyles();

    return (
        <div>
            <span className={classes.icon}>
                {icon}
            </span>
            <span>{primary}</span>
        </div>
    );
};

export default PrivateDashboardListItemV2;
